import axios from 'axios';
import { keycloak } from 'api/AuthKeycloak';

export const BASE_URL = process.env.REACT_APP_API_BASE_URL + process.env.REACT_APP_API_PATH;

const instance = axios.create({
    baseURL: BASE_URL,
    params: {
        realm: process.env.REACT_APP_KEYCLOAK_REALM
    },
    headers: { 'Content-Type': 'application/json; charset=UTF-8' },
});
instance.interceptors.response.use(res => res, (error) => {
    if (error.response.status === 401 && keycloak.authenticated) keycloak.logout();
    else throw error.response;
});

export default instance;

export const setAuthHeader = (token, config) => {
    if (config) {
        config.headers['Authorization'] = 'Bearer ' + token;
        return config;
    }
    else return {
        headers: {
            Authorization: 'Bearer ' + token
        }
    }
}