import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useFormik } from 'formik';
import * as Yup from 'yup'
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { selectPermissionsByKey, selectStagingSamplesFeature, selectStatusCodesTypesConf, selectResourcesTypesConf, selectResourceTypeGroupsConf } from "redux/configurationSlice";
import { updateLocationStatusCode } from "redux/locationsSlice";
import { userSelector } from "redux/userSlice";
import { PictureUploadButton } from "components";
import { Collapse, Box, Button, TextField, Typography, useTheme, useMediaQuery, IconButton, Pagination } from "@mui/material";
import { styled } from '@mui/material/styles';
import { ExpandMore } from '@mui/icons-material';
import { DateTimePicker, LocalizationProvider, MobileDateTimePicker } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { enGB, hr } from "date-fns/locale"
import API from "api";
import AddSampleCustomFields from "./AddSampleCustomFields";

const ExpandMoreCustom = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} color="primary" >
        <ExpandMore />
    </IconButton>;
})(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}));


export default function AddSampleForm(props) {
    const { location, setReportData, setAlert } = props;
    const theme = useTheme();
    const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const mediumScreen = useMediaQuery(theme => theme.breakpoints.down('lg'));
    const user = useSelector(userSelector);
    const statusCodeTypes = useSelector(selectStatusCodesTypesConf);
    const resourceTypesConf = useSelector(selectResourcesTypesConf);
    const manualResourceTypes = useSelector(selectResourceTypeGroupsConf).filter(el => el.name !== "SERVICE" && el.name !== "SENSOR");
    const resourceTypes = resourceTypesConf.filter(resource => location.resourceTypes.includes(resource.key) && manualResourceTypes.flatMap(el => el.resourceTypes).includes(resource.key) && !resource.name.includes("MANUAL"))
    const intl = useIntl();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const initialValues = Object.fromEntries(new Map([...resourceTypes.map(el => {
        if (el.dataType === 'Object') return [el.key.toString(), { value: '', description: '' }]
        else return [el.key.toString(), '']
    }), ['notes', ''], ['timestamp', new Date()]]));
    const [errorMsg, setErrorMsg] = useState(null);
    const [files, setFiles] = useState(null);
    const [images, setImages] = useState([]);
    const [expanded, setExpanded] = useState(manualResourceTypes.map((el, i) => true));
    const [page, setPage] = useState(Object.fromEntries(new Map([...manualResourceTypes.map(el => {
        return [el.name, 1]
    })])));
    const [filterName, setFilterName] = useState('');
    const [labels, setLabels] = useState({
        HZJZ: '',
        supplier: ''
    })

    const stagingFeature = useSelector(selectStagingSamplesFeature);
    const permissionList = useSelector(selectPermissionsByKey);
    const createStagingSample = permissionList['create-staging-sample'] ? true : false;
    const reviewStagingSample = permissionList['review-staging-sample'] ? true : false;
    const createSample = permissionList['create-sample'] ? true : false;
    const [disabledSubmitButton, setDisabledSubmitButton] = useState(false);

    const handleLabelsChange = (name) => (event) => {
        const { value } = event.target;
        setLabels((prevLabels) => ({
            ...prevLabels,
            [name]: value,
        }));
    };

    const validationSchema = resourceTypes.map(resource => {
        if (resource.range.min !== null && resource.range.max !== null) {
            return [
                resource.key.toString(),
                Yup.number().typeError(intl.formatMessage({ id: 'FORM.TYPE_ERROR' }))
                    .min(resource.range.min, `${intl.formatMessage({ id: 'FORM.MIN_ERROR' })} ${resource.range.min}`)
                    .max(resource.range.max, `${intl.formatMessage({ id: 'FORM.MAX_ERROR' })} ${resource.range.max}`)
            ];
        }
        else {
            if (resource.dataType === 'Float') return [resource.key.toString(),
            Yup.number().typeError(intl.formatMessage({ id: 'FORM.TYPE_ERROR' }))
            ]
            else if (resource.dataType === 'Object') return [resource.key.toString(),
            Yup.object().typeError(intl.formatMessage({ id: 'FORM.TYPE_ERROR' }))
            ]
            else if (resource.dataType === 'String') return [resource.key.toString(),
            Yup.string().typeError(intl.formatMessage({ id: 'FORM.TYPE_ERROR' }))
            ]
            else return [resource.key.toString(),
            Yup.boolean().typeError(intl.formatMessage({ id: 'FORM.TYPE_ERROR' }))
            ]
        }
    });
    validationSchema.push([
        'notes',
        Yup.string().typeError(intl.formatMessage({ id: 'FORM.TYPE_ERROR' }))
            .max(256, intl.formatMessage({ id: 'FORM.NOTES_ERROR' }))
    ]);
    validationSchema.push([
        'timestamp',
        Yup.date().typeError(intl.formatMessage({ id: 'FORM.DATE_ERROR' }))
            .max(new Date(), intl.formatMessage({ id: 'FORM.MAX_DATE_ERROR' }))
    ]);

    const formik = useFormik({
        initialValues: initialValues,
        validateOnBlur: false,
        validationSchema: Yup.object(Object.fromEntries(validationSchema)),
        onReset: (values) => {
            props.setSelectedLocation(null);
            navigate('/sampleEntry');
        },
        onSubmit: (values) => {
            setDisabledSubmitButton(true);
            let dataValues = {};
            for (let index = 0; index < Object.keys(values).length; index++) {
                const key = Object.keys(values)[index];
                // find only resources that are configured for location
                if (location.resourceTypes.includes(parseInt(key))) Object.assign(dataValues, { [key]: values[key] });
            }
            if (Object.values(dataValues).some(el => el !== "" && el.value !== "") || values.notes !== "") {

                Object.assign(dataValues, { 'notes': values.notes, 'timestamp': new Date(values.timestamp).getTime() });

                // token, collector, locationId, values, files
                if (stagingFeature) {
                    if (createSample) {
                        API.samplingLogs.postSamples(user.token, user.email, location._id, dataValues, labels, files).then(({ data }) => {
                            if (statusCodeTypes.length || data.complianceIndex) {
                                // dispatch action to update statusCodes for location and LocationGroup
                                dispatch(updateLocationStatusCode({
                                    locationId: location._id,
                                    locationGroupId: location.locationGroupId,
                                    locationStatusCode: data.locationStatusCode,
                                    groupStatusCode: data.groupStatusCode || [],
                                    complianceIndex: data.complianceIndex

                                }));
                            }
                            // trigger change view to Report
                            setReportData({ ...data, notes: values.notes, timestamp: values.timestamp, locationId: location._id, pictures: images });
                        }).catch((error) => {
                            setAlert({ open: true, messageId: (error.data && error.data.id) || "ERROR.NOT_CREATED", severity: "error" });
                            setDisabledSubmitButton(false);
                            if (error.status === 403) setErrorMsg(<FormattedMessage id="ACCESS_DENIED" />);
                            else setErrorMsg(error.data?.message || "");
                        });
                    }
                    else if (createStagingSample && !reviewStagingSample) {
                        API.samplingLogs.postStagingSamples(user.token, user.email, location._id, dataValues, labels, files).then(({ data }) => {
                            if (statusCodeTypes.length || data.complianceIndex) {
                                // dispatch action to update statusCodes for location and LocationGroup
                                dispatch(updateLocationStatusCode({
                                    locationId: location._id,
                                    locationGroupId: location.locationGroupId,
                                    locationStatusCode: data.locationStatusCode,
                                    groupStatusCode: data.groupStatusCode || [],
                                    complianceIndex: data.complianceIndex

                                }));
                            }
                            // trigger change view to Report
                            setReportData({ ...data, notes: values.notes, timestamp: values.timestamp, locationId: location._id, pictures: images });
                        }).catch((error) => {
                            setAlert({ open: true, messageId: (error.data && error.data.id) || "ERROR.NOT_CREATED", severity: "error" });
                            setDisabledSubmitButton(false);
                            if (error.status === 403) setErrorMsg(<FormattedMessage id="ACCESS_DENIED" />);
                            else setErrorMsg(error.data?.message || "");
                        });
                    }
                    else if (createStagingSample && reviewStagingSample) {
                        API.samplingLogs.postStagingSamplesReview(user.token, user.email, location._id, dataValues, labels, files).then(({ data }) => {
                            if (statusCodeTypes.length || data.complianceIndex) {
                                // dispatch action to update statusCodes for location and LocationGroup
                                dispatch(updateLocationStatusCode({
                                    locationId: location._id,
                                    locationGroupId: location.locationGroupId,
                                    locationStatusCode: data.locationStatusCode,
                                    groupStatusCode: data.groupStatusCode || [],
                                    complianceIndex: data.complianceIndex

                                }));
                            }
                            // trigger change view to Report
                            setReportData({ ...data, notes: values.notes, timestamp: values.timestamp, locationId: location._id, pictures: images });
                        }).catch((error) => {
                            setAlert({ open: true, messageId: (error.data && error.data.id) || "ERROR.NOT_CREATED", severity: "error" });
                            setDisabledSubmitButton(false);
                            if (error.status === 403) setErrorMsg(<FormattedMessage id="ACCESS_DENIED" />);
                            else setErrorMsg(error.data?.message || "");
                        });
                    }
                }
                else {
                    API.samplingLogs.postSamples(user.token, user.email, location._id, dataValues, labels, files).then(({ data }) => {
                        if (statusCodeTypes.length || data.complianceIndex) {
                            // dispatch action to update statusCodes for location and LocationGroup
                            dispatch(updateLocationStatusCode({
                                locationId: location._id,
                                locationGroupId: location.locationGroupId,
                                locationStatusCode: data.locationStatusCode,
                                groupStatusCode: data.groupStatusCode || [],
                                complianceIndex: data.complianceIndex
                            }));
                        }
                        // trigger change view to Report
                        setReportData({ ...data, notes: values.notes, timestamp: values.timestamp, locationId: location._id, pictures: images });
                    }).catch((error) => {
                        setAlert({ open: true, messageId: (error.data && error.data.id) || "ERROR.NOT_CREATED", severity: "error" });
                        setDisabledSubmitButton(false);
                        if (error.status === 403) setErrorMsg(<FormattedMessage id="ACCESS_DENIED" />);
                        else setErrorMsg(error.data?.message || "");
                    });
                }
            }
            else setErrorMsg(<FormattedMessage id="FORM.EMPTY_VALUES" />);

        }
    });
    useEffect(() => {
        setErrorMsg("");
    }, [formik.values])

    const renderForm = useCallback(() => {
        const perPageNumber = (() => {
            if (smallScreen) return 4;
            else if (mediumScreen) return 6;
            else return 8
        })();

        if (!location) return <Box><FormattedMessage id="NO_DATA" /></Box>;
        else return (
            <Box component="form" onSubmit={formik.handleSubmit}>
                {errorMsg ? <Typography sx={{ textAlign: 'center', mb: 2, color: theme.palette.error.main }}>{errorMsg}</Typography> : null}
                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={intl.locale === "hr" ? hr : enGB}>
                    {smallScreen ? <MobileDateTimePicker
                        label={<FormattedMessage id="TIMESTAMP" />}
                        value={formik.values['timestamp']}
                        onChange={(e) => formik.setFieldValue('timestamp', new Date(e))}
                        renderInput={(params) => <TextField {...params} size="small" fullWidth error={Boolean(formik.errors['timestamp'])}
                            helperText={formik.errors['timestamp']} />}
                    /> : <DateTimePicker
                        label={<FormattedMessage id="TIMESTAMP" />}
                        value={formik.values['timestamp']}
                        onChange={(e) => formik.setFieldValue('timestamp', new Date(e))}
                        renderInput={(params) => <TextField {...params} size="small" fullWidth error={Boolean(formik.errors['timestamp'])}
                            helperText={formik.errors['timestamp']} />}
                    />}
                </LocalizationProvider>

                <TextField
                    type="search"
                    id="outlined-helperText"
                    label={<FormattedMessage id="SEARCH_BY.RESOURCE_NAME" />}
                    value={filterName}
                    onChange={(e) => {
                        setFilterName(e.target.value); setPage(Object.fromEntries(new Map([...manualResourceTypes.map(el => {
                            return [el.name, 1]
                        })])))
                    }}
                    fullWidth
                    margin="normal"
                    sx={{ mt: 4 }}
                    size="small"
                />

                {manualResourceTypes.map((resGroup, index) => {
                    const filteredResourceTypes = resourceTypes.filter(res => {
                        if (!filterName) return resGroup.resourceTypes.includes(res.key);
                        else return resGroup.resourceTypes.includes(res.key) && intl.formatMessage({ id: res.name }).toUpperCase().includes(filterName.toUpperCase())
                    })
                    const pageCount = Math.ceil(filteredResourceTypes.length / perPageNumber);

                    if (filteredResourceTypes.length)
                        return <div key={index}>
                            <span style={{ cursor: 'pointer' }} onClick={() => setExpanded(manualResourceTypes.map((el, i) => i === index ? !expanded[i] : expanded[i]))}>
                                <Typography sx={{ pt: 2, display: 'inline-block' }} key={resGroup._id} color="primary" variant="h6"><FormattedMessage id={"RESOURCE_TYPE.GROUP." + resGroup.name} /></Typography>
                                <ExpandMoreCustom sx={{ mb: 1 }} expand={expanded[index]} />
                            </span>
                            <Collapse in={expanded[index]}>
                                {
                                    filteredResourceTypes.slice((page[resGroup.name] - 1) * perPageNumber, ((page[resGroup.name] - 1) * perPageNumber) + perPageNumber).map(resource => {
                                        return <AddSampleCustomFields resource={resource} formik={formik} key={resource.key} />
                                    }
                                    )
                                }
                                {filteredResourceTypes.length > perPageNumber && <Pagination count={pageCount} page={page[resGroup.name]} onChange={(e, value) => { setPage((prevState) => ({ ...prevState, [resGroup.name]: value })) }} siblingCount={2} size={smallScreen || mediumScreen ? 'small' : 'medium'} />}
                            </Collapse>
                        </div>
                    return null
                })}

                {Object.keys(labels).length ?
                    <>
                        <Typography sx={{ pt: 2 }} color="primary" variant="h6"><FormattedMessage id="SAMPLE_MARKS" /></Typography>
                        {Object.keys(labels).map((property) => (
                            <TextField
                                key={property}
                                variant="standard"
                                label={<FormattedMessage id={`SAMPLE_MARK_OF_${property.toUpperCase()}`} />}
                                fullWidth
                                margin="normal"
                                sx={{ p: 0, mt: 1 }}
                                type="text"
                                size="small"
                                name={property}
                                value={labels[property]}
                                onChange={handleLabelsChange(property)}
                            />
                        ))}
                    </>
                    : null}

                <Typography sx={{ pt: 2 }} color="primary" variant="h6"><FormattedMessage id="NOTES_AND_PICTURES" /></Typography>
                <TextField
                    multiline
                    rows="3"
                    name="notes"
                    label={<FormattedMessage id="NOTES" />}
                    placeholder={intl.formatMessage({ id: "NOTES_PLACEHOLDER" })}
                    value={formik.values['notes']}
                    onChange={formik.handleChange}
                    error={Boolean(formik.errors['notes'])}
                    helperText={formik.errors['notes']}
                    margin="normal"
                    variant="standard"
                    fullWidth
                />
                <Box sx={{ my: 3 }}>
                    <PictureUploadButton
                        setFiles={setFiles}
                        setImages={setImages}
                    />
                </Box>

                <Box sx={{ my: 2, position: 'absolute', bottom: '0%', right: smallScreen ? '16px' : '40px' }}>
                    <Button color="warning" onClick={formik.handleReset} ><FormattedMessage id="CANCEL" /></Button>
                    <Button disabled={Object.keys(formik.errors).length ? true : false || errorMsg ? true : false || disabledSubmitButton} type="submit"><FormattedMessage id="SUBMIT" /></Button>
                </Box>
            </Box>
        )
    }, [errorMsg, intl, location, resourceTypes, theme.palette, formik, smallScreen, expanded, manualResourceTypes, mediumScreen, filterName, page, labels, disabledSubmitButton]);

    return renderForm();
}