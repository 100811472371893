import React from "react";
import { FormattedMessage, useIntl } from 'react-intl';
import { DateRangeSelector } from 'components';
import { useSelector } from "react-redux";
import { selectSeverityTypesConf } from "redux/configurationSlice";
import { selectMeasurePoints } from "redux/locationsSlice";
import { Autocomplete, Box, Checkbox, FormControlLabel, TextField, useMediaQuery } from '@mui/material';

function AlarmsFilters(props) {
    const {
        filterHandler,
        severityTypesSelected,
        selectedLocations,
        selectAcknowledged,
        selectUnacknowledged,
        selectCleared,
        selectActive,
    } = props;
    const severityTypes = useSelector(selectSeverityTypesConf);
    const locations = useSelector(selectMeasurePoints);
    const smallScreen = useMediaQuery(theme => theme.breakpoints.down("md"));
    const intl = useIntl();

    return <Box sx={{ display: 'flex', flexDirection: !smallScreen ? 'row' : 'column', gap: '16px', justifyContent: 'space-evenly' }}>
        <Box sx={{ width: !smallScreen ? '30%' : '100%' }}>
            <DateRangeSelector column />
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px', py: 1, width: !smallScreen ? '30%' : '100%' }}>
            <Autocomplete
                multiple
                size="small"
                limitTags={3}
                disableCloseOnSelect
                id="multiple-limit-tags"
                options={severityTypes}
                filterSelectedOptions
                value={severityTypesSelected}
                getOptionLabel={(severity) => intl.formatMessage({ id: `SEVERITY.${severity.value}` })}
                renderInput={(params) => (
                    <TextField {...params} label={<FormattedMessage id={"SEVERITY"} />} />
                )}
                onChange={(e, severityType) => filterHandler(severityType.filter(mapedseverityType => severityTypes.map(filteredseverityType => filteredseverityType === mapedseverityType)), 'severityTypes')}
            />
            <Autocomplete
                multiple
                size="small"
                limitTags={3}
                disableCloseOnSelect
                id="multiple-limit-tags"
                options={locations}
                filterSelectedOptions
                value={selectedLocations}
                getOptionLabel={(location) => location.name}
                renderInput={(params) => (
                    <TextField {...params} label={<FormattedMessage id={"ALL_LOCATIONS"} />} />
                )}
                onChange={(e, location) => filterHandler(location.filter(mapedlocation => locations.map(filteredlocation => filteredlocation === mapedlocation)), 'locations')}
            />
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
                <Box sx={{ display: 'flex', flexDirection: 'row', gap: '8px' }}>
                    <FormControlLabel
                        control={<Checkbox size="small" checked={selectUnacknowledged} onChange={() => filterHandler(null, 'unack')} />}
                        label={<FormattedMessage id="UNACKNOWLEDGED" />}
                    />
                    <FormControlLabel
                        control={<Checkbox size="small" checked={selectAcknowledged} onChange={() => filterHandler(null, 'ack')} />}
                        label={<FormattedMessage id="ACKNOWLEDGED" />}
                    />
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'row', gap: '8px' }}>
                    <FormControlLabel
                        control={<Checkbox size="small" checked={selectActive} onChange={() => filterHandler(null, 'act')} />}
                        label={<FormattedMessage id="ACTIVE" />}
                    />
                    <FormControlLabel
                        control={<Checkbox size="small" checked={selectCleared} onChange={() => filterHandler(null, 'clr')} />}
                        label={<FormattedMessage id="CLEARED" />}
                    />
                </Box>
            </Box>
        </Box>
    </Box>;

}

export default AlarmsFilters;