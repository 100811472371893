import React, { useEffect, useState, useRef } from "react";
import { Autocomplete, Box, Button, Checkbox, Collapse, FormControl, FormControlLabel, FormGroup, FormLabel, Stack, TextField, Grid, Tooltip } from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import {
    selectLocalizationConf,
    selectResourcesTypesConf,
    selectResourceTypeGroupsConf,
    selectStatusCodesTypesConf,
    selectTypologyConf
} from "redux/configurationSlice";
import { selectMapProps } from "redux/mapSlice";
import { DisplayResourceName, PictureUploadButton, MapComponent, MapElementNew } from "components";
import { ExpandLessOutlined, ExpandMoreOutlined, InfoOutlined } from "@mui/icons-material";
import { Vector } from 'ol/source';
import { fromLonLat } from "ol/proj";


export default function LocationDetailsForm(props) {
    const { locationId, location, locationGroup, setUpdatedLocation, setErrorMsg } = props;
    const intl = useIntl();
    const mapProps = useSelector(selectMapProps);
    const typology = useSelector(selectTypologyConf);
    const resourceTypes = useSelector(selectResourcesTypesConf);
    const resourceTypeGroups = useSelector(selectResourceTypeGroupsConf);
    const statusTypes = useSelector(selectStatusCodesTypesConf);
    const localization = useSelector(selectLocalizationConf);
    const [name, setName] = useState(locationId ? location.name : '');
    const [description, setDescription] = useState(locationId ? location.description[intl.locale] : '');
    const [measurePointType, setMeasurePointType] = useState(locationId && location.measurePointType ? location.measurePointType : 0);
    const [position, setPosition] = useState(locationId ? location.position : [parseFloat(mapProps.defaultY), parseFloat(mapProps.defaultX)]);
    const [resources, setResources] = useState(Object.fromEntries(resourceTypes.map(el => (
        [el.key, {
            name: el.name,
            group: el.resourceTypeGroupId.name,
            checked: locationId && (location.resourceTypes || location.serviceResourceTypes) ?
                (location.resourceTypes.includes(parseInt(el.key)) || location.serviceResourceTypes.includes(parseInt(el.key))) : false,
            resource: el
        }]
    ))));
    const [statusCodeTypes, setStatusCodeTypes] = useState(Object.fromEntries(statusTypes.map(el => (
        [el.key, {
            name: el.key,
            status: locationId ? location.statusCode.find(status => Object.keys(status)[0] === el.key) ? Object.values(location.statusCode.find(status => Object.keys(status)[0] === el.key))[0] : 0 : 0,
            checked: locationId ? location.statusCode.find(status => Object.keys(status)[0] === el.key) ? true : false : false
        }]
    ))));
    const locationImageUrl = process.env.REACT_APP_API_BASE_URL + process.env.REACT_APP_IMG_PATH + (locationId ? location.picture : process.env.REACT_APP_DEFAULT_IMAGE);
    const [image, setImage] = useState(null);
    const [expandedResourceGroups, setExpandedResourceGroups] = useState(Object.fromEntries(resourceTypeGroups.map(el => [el.name, true])));
    // initialize new VectorSource for VectorLayer
    const markerSource = useRef(new Vector());

    useEffect(() => {

        const locationDescription = location ? location.description : { en: "", hr: "" }

        const changedLocationInfo = {
            name: name,
            description: Object.fromEntries(new Map(localization.map(el => {
                if (locationId) {
                    if (el.value === intl.locale) return [el.value, description];
                    else return [el.value, locationDescription[el.value] || '']
                }
                else {
                    if (el.value === intl.locale) return [el.value, description];
                    else return [el.value, ''];
                }
            }))),
            ...(!locationGroup) && { measurePointType: measurePointType },
            position: position,
            ...(!locationGroup) && { resourceTypes: Object.entries(resources).map(([key, value]) => value.checked && value.group !== "SERVICE" ? parseInt(key) : null).filter(el => el !== null) },
            ...(!locationGroup) && { serviceResourceTypes: Object.entries(resources).map(([key, value]) => value.checked && value.group === "SERVICE" ? parseInt(key) : null).filter(el => el !== null) },
            statusCode: Object.entries(statusCodeTypes).map(([key, value]) => value.checked ? { [key]: value.status } : null).filter(el => el !== null),
            ...image && { picture: image }
        };
        setUpdatedLocation(changedLocationInfo);
        setErrorMsg("")

    }, [setErrorMsg, setUpdatedLocation, locationId, locationGroup, name, description, measurePointType, position, resources, statusCodeTypes, image, intl, localization, location])

    const handleExpandResourceGroupClick = (rtGroup, value) => {
        const expandResGroups = { ...expandedResourceGroups }
        if (rtGroup === "all") {
            for (const rtg in expandResGroups) {
                expandResGroups[rtg] = value;
            }
        }
        else {
            expandResGroups[rtGroup] = !expandResGroups[rtGroup]
        }

        setExpandedResourceGroups(expandResGroups);
    };

    const handleResourcesCheck = (checkFlag, key) => {
        const res = { ...resources };
        res[key].checked = checkFlag;
        setResources(res);
    }

    const handleResourcesGroupCheck = (checkFlag, rtGroup) => {
        const res = { ...resources };
        for (const rtKey in res) {
            if (res[rtKey].group === rtGroup) res[rtKey].checked = checkFlag
        }
        setResources(res);

    }

    const handleStatusCheck = (checkFlag, key) => {
        const status = { ...statusCodeTypes };
        status[key].checked = checkFlag;
        setStatusCodeTypes(status);
    }

    const handlePositionChange = (lng, lat) => {
        const newPosition = [];
        parseFloat(lng) ? newPosition.push(parseFloat(lng)) : newPosition.push(0);
        parseFloat(lat) ? newPosition.push(parseFloat(lat)) : newPosition.push(0);
        setPosition(newPosition);

        if (markerSource.current.getFeatures().length !== 1) {
            // shouldn't happen
            return;
        }

        const pinFeature = markerSource.current.getFeatures()[0];
        // change coordinates for the existing Feature on the map
        pinFeature.getGeometry().setCoordinates(fromLonLat(newPosition));
    }

    const resourceTypesCheckboxes = Object.entries(resources).map(([key, el]) => {
        return <FormControlLabel
            key={key}
            control={
                <Checkbox
                    sx={{ px: '9px', py: '5px' }}
                    checked={el.checked}
                    onChange={(e) => handleResourcesCheck(e.target.checked, key)}
                />
            }
            label={<DisplayResourceName resource={el.resource} />}
            name={el.group}
        />
    });


    const statusCodeTypesCheckboxes = Object.entries(statusCodeTypes).map(([key, el]) => {
        return <FormControlLabel
            key={key}
            control={
                <Checkbox
                    sx={{ px: '9px', py: '5px' }}
                    checked={el.checked}
                    onChange={(e) => handleStatusCheck(e.target.checked, key)}
                />
            }
            label={<FormattedMessage id={key} />}
        />
    });

    return (
        <Box component="form" >
            <Box sx={{ py: 1 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={!locationGroup ? 8 : 12} lg={!locationGroup ? 8 : 12} xl={!locationGroup ? 8 : 12}>
                        <FormLabel><FormattedMessage id="NAME" />*</FormLabel>
                        <TextField
                            fullWidth
                            variant="outlined"
                            id="NAME"
                            placeholder={intl.formatMessage({ id: "NAME" })}
                            type="text"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            size="small"
                        />
                    </Grid>
                    {!locationGroup && <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                        <FormLabel><FormattedMessage id="LOCATION.TYPE" /></FormLabel>
                        <Autocomplete
                            id="LOCATION.TYPE"
                            disableClearable
                            size="small"
                            options={typology.map(type => type.key)}
                            getOptionLabel={(type) => intl.formatMessage({ id: "LOCATION_TYPE." + typology.find(mpType => mpType.key === type).value })}
                            value={measurePointType}
                            onChange={(e, value) => { setMeasurePointType(value) }}
                            renderInput={(params) => (
                                <TextField {...params} />
                            )}
                            sx={{ width: '100%' }}
                        />
                    </Grid>}
                </Grid>
            </Box>


            <Box sx={{ py: 1 }}>
                <FormLabel>
                    <FormattedMessage id="DESCRIPTION" /> (
                    {intl.locale}
                    <Tooltip placement="right" title={<FormattedMessage id="SAVE_LOCALE_TOOLTIP" />} arrow>
                        <InfoOutlined color="disabled" sx={{ width: '16px', height: '16px' }} />
                    </Tooltip>
                    )
                </FormLabel>
                <TextField
                    fullWidth
                    multiline
                    rows="5"
                    id="DESCRIPTION"
                    placeholder={intl.formatMessage({ id: "DESCRIPTION" })}
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    size="small"
                />
            </Box>

            <Box sx={{ py: 1 }} className="location-details-form-map">

                <FormLabel><FormattedMessage id="POSITION" />*</FormLabel>
                <div style={{ height: '200px' }}>
                    <MapComponent className="pin-input" zoom={locationId ? 12 : 10} position={position} disableShapes>
                        <MapElementNew
                            key="map-elements"
                            markerSource={markerSource.current}
                            positionInput={position}
                            positionChange={setPosition}
                        />
                    </MapComponent>
                </div>

                <Stack direction="row" spacing={1} sx={{ mt: 2 }}>
                    <TextField
                        key="lng"
                        fullWidth
                        id="LONGITUDE"
                        type="number"
                        label={<FormattedMessage id="LONGITUDE" />}
                        inputProps={{ inputMode: 'decimal', step: 0.000001 }}
                        value={position[0]}
                        onChange={(e) => handlePositionChange(e.target.value, position[1])}
                        variant="outlined"
                        size="small"
                    />
                    <TextField
                        key="lat"
                        fullWidth
                        id="LATITUDE"
                        type="number"
                        label={<FormattedMessage id="LATITUDE" />}
                        inputProps={{ inputMode: 'decimal', step: 0.000001 }}
                        value={position[1]}
                        onChange={(e) => handlePositionChange(position[0], e.target.value)}
                        variant="outlined"
                        size="small"
                    />
                </Stack>
            </Box>

            {statusCodeTypesCheckboxes.length ?
                <Box sx={{ py: 1 }}>

                    <FormControl component="fieldset">
                        <FormLabel component="legend"><FormattedMessage id="QUALITY_INDEX" /></FormLabel>
                        <FormGroup>
                            {statusCodeTypesCheckboxes}
                        </FormGroup>
                    </FormControl>
                </Box>
                : null
            }


            {!locationGroup ?
                <Box sx={{ py: 1 }}>
                    <FormControl component="fieldset">
                        <Box>
                            <FormLabel><FormattedMessage id="MEASUREMENT_TYPES" /></FormLabel>
                            {Object.entries(expandedResourceGroups).every(([key, value]) => value) ?
                                <Button sx={{ mx: 2 }} variant="text" onClick={() => handleExpandResourceGroupClick("all", false)}><FormattedMessage id="COLLAPSE" /></Button> :
                                <Button sx={{ mx: 2 }} variant="text" onClick={() => handleExpandResourceGroupClick("all", true)}><FormattedMessage id="EXPAND" /></Button>
                            }
                        </Box>
                        {resourceTypeGroups.map(rtGroup =>
                            <Box sx={{ mb: '4px' }} key={rtGroup._id}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={Object.entries(resources).filter(([key, value]) => value.group === rtGroup.name).every(([key, value]) => value.checked)}
                                            indeterminate={Object.entries(resources).filter(([key, value]) => value.group === rtGroup.name).some(([key, value]) => value.checked)
                                                && Object.entries(resources).filter(([key, value]) => value.group === rtGroup.name).some(([key, value]) => !value.checked)}
                                            onChange={(e) => handleResourcesGroupCheck(e.target.checked, rtGroup.name)}
                                        />
                                    }
                                    label={<FormattedMessage id={"RESOURCE_TYPE.GROUP." + rtGroup.name} />}
                                />
                                {expandedResourceGroups[rtGroup.name] ?
                                    <ExpandLessOutlined sx={{ position: 'relative', top: '8px' }} onClick={() => handleExpandResourceGroupClick(rtGroup.name)} /> :
                                    <ExpandMoreOutlined sx={{ position: 'relative', top: '8px' }} onClick={() => handleExpandResourceGroupClick(rtGroup.name)} />
                                }
                                <Collapse in={expandedResourceGroups[rtGroup.name]} timeout="auto" unmountOnExit>
                                    <FormGroup sx={{ ml: 2 }}>
                                        {resourceTypesCheckboxes.filter(el => el.props.name === rtGroup.name)}
                                    </FormGroup>
                                </Collapse>
                            </Box>
                        )}
                    </FormControl>
                </Box>
                : null
            }


            <Box sx={{ py: 1 }}>
                <PictureUploadButton
                    defaultImage={{ name: locationId ? location.name : process.env.REACT_APP_DEFAULT_IMAGE, url: locationImageUrl }}
                    setFiles={setImage}
                    single
                />
            </Box>
        </Box>
    );
}