import React, { useCallback, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router';
import { useSelector } from 'react-redux';
import { userSelector, permissionSelector } from 'redux/userSlice';
import { selectMeasurePoints } from 'redux/locationsSlice';
import { AlarmProfileButtons, CustomDataGrid, AlarmProfileCard, SideDrawer } from "components";
import { IconButton, Box, Chip, Tooltip, useMediaQuery, useTheme } from '@mui/material';
import { LabelImportantOutlined, NotificationsPausedOutlined, NotificationsActiveOutlined, ReportOutlined, ReportOffOutlined, EditOutlined } from '@mui/icons-material';


export default function AlarmProfileTable(props) {
  const { data, updateAlarmsData } = props;
  const intl = useIntl();
  const navigate = useNavigate();
  const user = useSelector(userSelector);
  const locations = useSelector(selectMeasurePoints);
  const updateRights = useSelector((state) => permissionSelector(state, 'update-alarmProfiles'));
  const [details, setDetails] = useState({});
  const [detailsAlarm, setDetailsAlarm] = useState({});
  const [openDrawer, setDrawer] = useState(false);
  const smallScreen = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const fontSize = useTheme().typography.fontSize;

  const renderTable = useCallback(() => {
    const headers = [
      {
        field: 'enabled',
        headerName: intl.formatMessage({ id: 'ENABLED' }),
        ...(!smallScreen && { flex: 0 }),
        renderCell: ({ value }) => value ? <NotificationsActiveOutlined color="success" /> : <NotificationsPausedOutlined color="disabled" />,
        minWidth: 50
      },
      {
        field: 'triggered',
        headerName: intl.formatMessage({ id: 'TRIGGERED' }),
        renderCell: ({ value }) => value ? <ReportOutlined color="warning" /> : <ReportOffOutlined color="disabled" />,
        ...(!smallScreen && { flex: 0 }),
        minWidth: 50
      },
      {
        field: 'name',
        headerName: intl.formatMessage({ id: 'ALARM.PROFILE.NAME' }),
        ...(!smallScreen && { flex: 2 }),
        minWidth: 150
      },
      {
        field: 'location',
        headerName: intl.formatMessage({ id: 'LOCATION' }),
        ...(!smallScreen && { flex: 2 }),
        minWidth: 150
      },
      {
        field: 'rulesArray',
        headerName: intl.formatMessage({ id: 'ALARM.PROFILES.RULES' }),
        ...(!smallScreen && { flex: 0 }),
        renderCell: ({ value }) => {
          return value.length ? <Chip label={value.map(rule => {
            return <Tooltip key={rule} title={<FormattedMessage id={'SEVERITY.' + rule} />} arrow >
              <LabelImportantOutlined
                name={'severity-' + rule}
                className={'severity-' + rule}
                sx={{ width: fontSize * 1.5, height: fontSize * 1.5, pt: 0.5 }}
              />
            </Tooltip>
          })}
          /> : null
        },
        minWidth: 150
      },
    ];

    if (updateRights) headers.push({
      field: 'actions',
      headerName: intl.formatMessage({ id: 'ALARM.PROFILE.ACTIONS' }),
      type: 'actions',
      getActions: (el) => [
        <AlarmProfileButtons
          key={el.id}
          alarmProfileId={el.row._id}
          locationId={el.row.locationId}
          enabled={el.row.enabled}
          user={user}
          alarm={el.row}
          updateAlarmsData={updateAlarmsData}>
          <Tooltip key="edit" title={<FormattedMessage id="EDIT" />} arrow>
            <IconButton color="primary" onClick={() => navigate(el.row._id + '?edit')}>
              <EditOutlined name="info" fontSize="small" />
            </IconButton>
          </Tooltip>
        </AlarmProfileButtons>
      ],
      flex: 2,
      minWidth: 150
    });

    const handleData = ({ row }) => {
      setDetailsAlarm(row);
      setDrawer(true);
      // navigate(row.id);
    }

    const getCellClassName = (params) => {
      if (params.field === '#' && params.value !== null) {
        return params.value;
      }
    }

    return <CustomDataGrid customCellClass={getCellClassName} rows={data.map(el => ({ ...el, location: locations.find(l => l._id === el.locationId).name }))} columns={headers} handleData={handleData} />;

  }, [intl, data, locations, user, updateAlarmsData, updateRights, smallScreen, fontSize, navigate]);

  useEffect(() => {
    if (detailsAlarm && detailsAlarm._id) {
      // const alarm = data.find(alarm => alarm._id === detailsAlarm._id);
      // if (alarm && (alarm.acknowledgedBy !== detailsAlarm.acknowledgedBy || alarm.clearedBy !== detailsAlarm.clearedBy)) {
      //   setDetailsAlarm(alarm);
      // }

      try {
        setDetails({
          title: <FormattedMessage id='ALARM.PROFILE.DETAILS_CARD' />,
          subtitle: <Chip size="small" color={detailsAlarm.enabled ? "success" : "default"} label={<FormattedMessage id={detailsAlarm.enabled ? "ENABLED" : "DISABLED"} />} icon={detailsAlarm.enabled ? <NotificationsActiveOutlined fontSize="small" /> : <NotificationsPausedOutlined fontSize="small" />} />,
          display: <AlarmProfileCard mini alarm={detailsAlarm} updateAlarmData={updateAlarmsData} />
        })
      } catch (err) {
        setDetails({
          title: <FormattedMessage id='SAMPLING_LOG_REPORT.ERROR' />,
          subtitle: '',
          display: <div />
        });
      }
    }
  }, [detailsAlarm, updateAlarmsData])

  return <Box>
    {renderTable()}
    <SideDrawer state={details} open={openDrawer} toggleDrawer={() => setDrawer(false)} />
  </Box>
}