import { useState } from 'react';
import { useSelector } from 'react-redux';
import { selectNotificationMethodConf } from 'redux/configurationSlice';
import { FormattedMessage, useIntl } from 'react-intl';
import { AlarmProfileCondition } from 'components';
import { Dialog, DialogContent, DialogContentText, DialogTitle, DialogActions, Autocomplete, Card, CardHeader, Box, Stack, Tooltip, Typography, CardContent, IconButton, FormLabel, TextField, MenuItem, Button } from '@mui/material';
import { LabelImportantOutlined, AddOutlined, DeleteOutlined } from '@mui/icons-material';


const SecondaryText = (props) => {
    return <Stack sx={{ alignItems: 'center' }} spacing={0.5} direction={props.direction}>
        <Typography variant="body2" color='primary.main'><FormattedMessage id={props.messageId} /></Typography>
        {props.children ? props.children : <Typography variant='body1'><FormattedMessage id={props.valueId} /></Typography>}
    </Stack>
};


export default function AlarmProfileRules(props) {
    const { alarmRule, rule, updateAlarm, locationId, severityTypes, usedTypes } = props;
    const [filters, setFilters] = useState(alarmRule.filters);
    const [ruleMessage, setRuleMessage] = useState(alarmRule.details);
    const notificationOptions = useSelector(selectNotificationMethodConf);
    const [selectedNotificationTypes, setSelectedNotificationTypes] = useState(notificationOptions.filter(el => alarmRule.notificationMethod.includes(el.key)));
    const [openDialog, setOpenDialog] = useState(false);
    const intl = useIntl();
    const [ruleSeverity, setRuleSeverity] = useState(rule)

    const handleNewFilter = (event) => {
        const newFilter = { type: "SINGLE", conditions: [] };
        setFilters([...filters, newFilter]);
        updateAlarm(newFilter, 'filters', rule, filters.length);
    }

    return <Card sx={{ width: '100%', mb: 2 }}>
        <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
            <DialogTitle>
                <FormattedMessage id="ALERT.RULE" />
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <FormattedMessage id="ALERT.RULE.INFO" /> {filters.length}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setOpenDialog(false)} autoFocus><FormattedMessage id="CANCEL" /></Button>
                <Button onClick={() => updateAlarm(rule, 'rules-delete')}><FormattedMessage id="DELETE" /></Button>
            </DialogActions>
        </Dialog>
        <CardHeader title={
            <span>
                <TextField
                    id="select-severity"
                    key="select-severity"
                    fullWidth
                    sx={{ mb: 2 }}
                    size="small"
                    select
                    value={ruleSeverity}
                    onChange={(e) => {
                        setRuleSeverity(e.target.value)
                        updateAlarm(e.target.value, 'rule-severity-edit', rule)
                    }}
                >
                    {severityTypes.map((sevType, index) => (<MenuItem disabled={usedTypes.includes(sevType.value) && sevType.value !== rule} key={index} value={sevType.value}>
                        <Box >
                            <LabelImportantOutlined
                                name={'severity-' + sevType.value}
                                className={'severity-' + sevType.value}
                                sx={(theme) => ({ width: theme.typography.fontSize * 1.5, height: theme.typography.fontSize * 1 })}
                            />
                            <FormattedMessage id={"SEVERITY." + sevType.value} />
                        </Box>
                    </MenuItem>))}

                </TextField>
            </span>
        }
            subheader={<SecondaryText direction="row" messageId="TYPE" valueId={"TYPE." + alarmRule.type} />}
            action={<Tooltip title={<FormattedMessage id={"DELETE.RULE"} />} arrow>
                <IconButton onClick={() => setOpenDialog(true)}><DeleteOutlined /></IconButton>
            </Tooltip>}
        />
        <CardContent sx={{ pt: 0 }}>
            <FormLabel sx={{ color: 'primary.main' }}><FormattedMessage id="NOTIFICATION_TYPE" /></FormLabel>
            <Autocomplete
                multiple
                size="small"
                limitTags={3}
                isOptionEqualToValue={(option, value) => option.key === value.key}
                disableCloseOnSelect
                filterSelectedOptions
                onChange={(event, newValue) => {
                    setSelectedNotificationTypes(newValue);
                    updateAlarm(newValue.map(el => el.key), 'rules-edit', rule);
                }}
                id="multiple-limit-tags"
                value={selectedNotificationTypes}
                options={notificationOptions}
                getOptionLabel={(option) => intl.formatMessage({ id: 'NOTIFICATION_OPTION.' + option.value }) || ""}
                renderInput={(params) => (
                    <TextField {...params} />
                )}
                fullWidth
            />
            <Box sx={{ mt: 2.5 }} />
            <FormLabel sx={{ color: 'primary.main' }}><FormattedMessage id="MESSAGE" /></FormLabel>
            <TextField
                fullWidth
                rows="5"
                overflow-wrap="break-word"
                variant="outlined"
                id={"MESSAGE"}
                placeholder={intl.formatMessage({ id: "MESSAGE" })}
                type="text"
                value={ruleMessage}
                onChange={(e) => {
                    setRuleMessage(e.target.value);
                }}
                onBlur={() => updateAlarm(ruleMessage, 'message', rule)}
                size="small"
            />
            <Box sx={{ mt: 2.5 }} />
            {!!filters.length && <FormLabel sx={{ color: 'primary.main' }}><FormattedMessage id="FILTERS" /></FormLabel>}
            {!!filters && filters.map((filter, filtIndex) => <AlarmProfileFilter key={rule + filtIndex} rule={rule} updateAlarm={updateAlarm} filter={filter} filtIndex={filtIndex} locationId={locationId} />)}
            <Button onClick={handleNewFilter} startIcon={<AddOutlined />}>
                <FormattedMessage id="FILTER_ADD" />
            </Button>
        </CardContent>
    </Card >
}


const AlarmProfileFilter = (props) => {
    const { rule, filter, filtIndex, updateAlarm, locationId } = props;
    const [newConditionFlag, setNewConditionFlag] = useState(false);
    const [type, setType] = useState(filter.type);
    const [validFlag, setValidFlag] = useState(false);
    const filterTypes = ["AND", "OR", "SINGLE"];

    const handleDeleteFilter = (filterIndex) => {
        updateAlarm(undefined, 'filters', rule, filterIndex);
    }

    const handleNewCondition = () => {
        setNewConditionFlag(true);
    }

    const updateAlarmHandler = (...args) => {

        if (args[0] && args[0].resourceTypeKey === null) setValidFlag(true);
        else setValidFlag(false);

        setNewConditionFlag(false);
        updateAlarm(...args);
    };

    return <div key={filtIndex}>
        <Box sx={{ border: '1px solid lightgray', p: 2, mb: 1 }}>
            <CardHeader sx={{ p: 1 }} title={<SecondaryText direction="row" messageId="TYPE" >
                <TextField
                    sx={{ mr: 2, textAlign: 'center' }}
                    select
                    size="small"
                    defaultValue={type}
                    onChange={(e) => {
                        setType(e.target.value);
                        updateAlarm({ ...filter, type: e.target.value }, 'filters', rule, filtIndex)
                    }}
                >
                    {filterTypes.map((type, index) => (
                        <MenuItem key={index} value={type}>
                            <FormattedMessage id={"FILTERS." + type} />
                        </MenuItem>
                    ))}
                </TextField></SecondaryText>} action={<Tooltip title={<FormattedMessage id={"DELETE.FILTER"} />} arrow>
                    <IconButton onClick={() => handleDeleteFilter(filtIndex)}><DeleteOutlined /></IconButton>
                </Tooltip>} />
            {filter.conditions.map((condition, condIndex) => {
                let disabled = false;
                if (type === "SINGLE" && condIndex > 0) disabled = true;
                return <AlarmProfileCondition disabled={disabled} key={JSON.stringify(condition) + condIndex} condition={condition} index={condIndex} filtIndex={filtIndex} rule={rule} updateAlarm={updateAlarmHandler} locationId={locationId} />
            })}

            {newConditionFlag && <AlarmProfileCondition index={filter.conditions.length} filtIndex={filtIndex} rule={rule} updateAlarm={updateAlarmHandler} locationId={locationId} />}
            <Button disabled={Boolean(newConditionFlag || validFlag || (type === 'SINGLE' && filter.conditions.length))} startIcon={<AddOutlined />} onClick={handleNewCondition} >
                <FormattedMessage id="ADD.CONDITION" />
            </Button>
        </Box>
    </div>;
}