import React, { useRef, useState } from 'react';
import { FooterIRI } from 'components';
import { useIntl } from 'react-intl';
import { useNavigate } from "react-router-dom";
import './landingPageIRI.scss';

function LandingPageIRI(props) {
    const { language } = props;
    const intl = useIntl();
    const navigate = useNavigate();
    const videoRef = useRef(null);
    const [show, setShow] = useState(true);
    const handlePlayVideo = () => {
        videoRef.current.play();
        setShow(false);
    }

    const handleLoadVideo = () => {
        videoRef.current.load()
    }
    const videoSource = language === 'hr' ? window.location.origin + '/videos/WaterQ_2022_HR.mp4' : window.location.origin + '/videos/WaterQ_2022_EN.mp4';

    return (
        <div className='landing-page-body'>
            <div className='image-container'>
                <video className='waterq-iri-video' src={videoSource} controls={!show ? "controls" : false} ref={videoRef} poster={window.innerWidth > 960 ? window.location.origin + "/images/IRI/LandingPage/shutterstock.jpg" : window.location.origin + "/images/IRI/LandingPage/shutterstock-mobile.png"} onEnded={() => { handleLoadVideo(); setShow(true) }} />
                <img className={show ? 'play-button' : 'play-button-hidden'} onClick={() => handlePlayVideo()} src={window.location.origin + '/images/IRI/LandingPage/play-button.jpg'} alt='play' />
                <hr className={show ? 'separator-line-image' : 'separator-line-image-hidden'} />
                <div className={show ? 'image-caption1' : 'image-caption1-hidden'}>{intl.formatMessage({ id: 'LANDING_PAGE_IRI.IMAGE_CAPTION_ONE' }).toUpperCase()}</div>
                <div className={show ? 'image-caption2' : 'image-caption2-hidden'}>{intl.formatMessage({ id: 'LANDING_PAGE_IRI.IMAGE_CAPTION_TWO' })}</div>
                <img className={show ? 'droplet-icon' : 'droplet-icon-hidden'} src={window.location.origin + '/images/IRI/LandingPage/droplet-icon.png'} alt='droplet' />
            </div>
            <div className='landing-page-container'>
                <hr className='separator-line-landing' />
                <div className='landing-page-description'>
                    {intl.formatMessage({ id: 'LANDING_PAGE_IRI.DESCRIPTION' })}
                </div>

                <div className='waterq-water-quality'>
                    <div className='infographic-01-title'>{intl.formatMessage({ id: 'LANDING_PAGE_IRI.INFOGRAPHIC_ONE_TITLE' })}</div>
                    <img className='infographic-01' src={window.innerWidth > 960 ? window.location.origin + '/images/IRI/LandingPage/Infographic-01.jpg' : window.location.origin + '/images/IRI/LandingPage/Infographic-1-mobile.png'} alt='Infographic-01' />
                    <div className='infographic-01-subtitle-one'>
                        {intl.formatMessage({ id: 'LANDING_PAGE_IRI.INFOGRAPHIC_ONE_SUBTITLE_ONE' })}
                    </div>
                    <div className='infographic-01-subtitle-two'>
                        {intl.formatMessage({ id: 'LANDING_PAGE_IRI.INFOGRAPHIC_ONE_SUBTITLE_TWO' })}
                    </div>
                    <div className='infographic-01-subtitle-three'>
                        {intl.formatMessage({ id: 'LANDING_PAGE_IRI.INFOGRAPHIC_ONE_SUBTITLE_THREE' })}
                    </div>
                    <div className='infographic-01-subtitle-four'>
                        {intl.formatMessage({ id: 'LANDING_PAGE_IRI.INFOGRAPHIC_ONE_SUBTITLE_FOUR' })}
                    </div>
                </div>

                <div className='waterq-system-title'>{intl.formatMessage({ id: 'LANDING_PAGE_IRI.SYSTEM_TITLE' })}</div>
                <div className='waterq-system-subtitles'>
                    <div className='waterq-system-subtitle-one'>{intl.formatMessage({ id: 'LANDING_PAGE_IRI.SYSTEM_SUBTITLE_ONE' })}</div>
                    <div className='waterq-system-subtitle-two'>{intl.formatMessage({ id: 'LANDING_PAGE_IRI.SYSTEM_SUBTITLE_TWO' })}</div>
                    <div className='waterq-system-subtitle-three'>{intl.formatMessage({ id: 'LANDING_PAGE_IRI.SYSTEM_SUBTITLE_THREE' })}</div>
                </div>
                <div className='waterq-system-container'>
                    <img className='IoT-sensor' src={window.location.origin + '/images/IRI/LandingPage/IoT-sensor.jpg'} alt='IoT-sensor' />
                    <img className='laboratory-analysis' src={window.location.origin + '/images/IRI/LandingPage/Laboratory-analysis.png'} alt='Laboratory-analysis' />
                    <img className='Infographic-02' src={window.location.origin + '/images/IRI/LandingPage/Infographic-02.png'} alt='Infographic-02' />
                </div>
                <div className='waterq-platform'>
                    <div className='waterq-platform-title'>{intl.formatMessage({ id: 'LANDING_PAGE_IRI.PLATFORM_TITLE' })}</div>
                    <div className='waterq-platform-subtitle'>{intl.formatMessage({ id: 'LANDING_PAGE_IRI.PLATFORM_SUBTITLE' })}</div>
                    <img className='infographic-03' src={window.innerWidth > 960 ? window.location.origin + '/images/IRI/LandingPage/Infographic-03.png' : window.location.origin + '/images/IRI/LandingPage/Infographic-mobile.png'} alt='Infographic-03' />
                    <img className='analysis' src={window.location.origin + '/images/IRI/LandingPage/analysis.png'} alt='analysis' />
                </div>
                <div className='waterq-results'>
                    <div className='waterq-results-title'>{intl.formatMessage({ id: 'LANDING_PAGE_IRI.RESULTS_TITLE' })}</div>
                    <div className='waterq-results-subtitles'>
                        <div className='waterq-results-subtitle-one'>{intl.formatMessage({ id: 'LANDING_PAGE_IRI.RESULTS_SUBTITLE_ONE' })}</div>
                        <div className='waterq-results-subtitle-two'>{intl.formatMessage({ id: 'LANDING_PAGE_IRI.RESULTS_SUBTITLE_TWO' })}</div>
                    </div>
                    <img className='infographic-line' src={window.innerWidth > 960 ? null : window.location.origin + '/images/IRI/LandingPage/Infographic-mobile.png'} alt='Infographic-03' />
                    <div className='waterq-mobile-aplication'>
                        <div className='waterq-mobile-aplication-container'>
                            <img className='infographic-04' src={window.location.origin + '/images/IRI/LandingPage/Infographic-04.png'} alt='Infographic-04' />
                            <img className='infographic-05' src={window.location.origin + '/images/IRI/LandingPage/Infographic-05.png'} alt='Infographic-05' />
                        </div>
                        <img className='infographic-06' src={window.innerWidth > 960 ? window.location.origin + '/images/IRI/LandingPage/Infographic-06.png' : window.location.origin + '/images/IRI/LandingPage/Infographic-mobile.png'} alt='Infographic-06' />
                    </div>
                </div>

                <div className='image-container-two'>
                    <div className='integration-resolution'>{intl.formatMessage({ id: 'LANDING_PAGE_IRI.INTEGRATION_RESOLUTION' })}</div>
                    <button onClick={() => navigate('/aboutIRI')} className='find-out-more'>{intl.formatMessage({ id: 'LANDING_PAGE_IRI.FIND_OUT_MORE' }).toUpperCase()}</button>
                    <img className='shutterstock-02' src={window.location.origin + '/images/IRI/LandingPage/shutterstock-02.png'} alt='shutterstock-02' />
                </div>
            </div>
            <FooterIRI />
        </div>
    )
}

export default LandingPageIRI;