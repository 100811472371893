import React, { useCallback, useState, useRef } from "react";
import { Stack } from '@mui/material';
import { StagingSamplesTableContainer, StagingSampleReportingFilters } from "components";

export default function StagingSamplesView(props) {
    const { location } = props;
    const chartRef = useRef(null);

    // STATE & CALLBACKS
    const [locations, setLocations] = useState(location ? [location] : []);
    const [sampleTypes, setSampleTypes] = useState([]);
    const [qualityTypes, setQualityTypes] = useState([]);
    const [pictureSample, togglePictureSample] = useState(false);

    const filterHandler = useCallback((data, type) => {
        switch (type) {
            case 'locations':
                setLocations(data);
                return;
            case 'samples':
                setSampleTypes(data);
                return;
            case 'quality':
                setQualityTypes(data);
                return;
            case 'picture':
                togglePictureSample(data);
                return;
            default:
                setSampleTypes([]);
                setQualityTypes([]);
                togglePictureSample(false);
                return;
        }
    }, [setSampleTypes, setLocations, setQualityTypes, togglePictureSample]);

    const render = useCallback(() => {
        return <Stack direction="column" sx={{ width: '100%' }} spacing={2}>
            <StagingSampleReportingFilters
                multiLocation
                selectedLocations={locations}
                filterHandler={filterHandler}
                sampleTypes={sampleTypes}
                qualityTypesSelected={qualityTypes}
                pictureSample={pictureSample}
            />

            <StagingSamplesTableContainer
                locations={locations.map(loc => loc._id)}
                sampleTypes={sampleTypes.map(sample => sample.key)}
                qualityTypes={qualityTypes.map(quality => quality.key)}
                pictureSample={pictureSample}
                chartRef={chartRef}
            />
        </Stack>

    }, [locations, sampleTypes, qualityTypes, pictureSample, chartRef, filterHandler])

    return <>
        {render()}
    </>
}