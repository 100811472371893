import instance, { setAuthHeader } from './axiosEnv';

const getAllAlarmProfiles = (token, locations = '', nameIncludes, severities = '', selectedNotificationTypes = '', selectEnabled, selectTriggered, displayAll) => {
    const config = setAuthHeader(token);
    const queryParams = new URLSearchParams();

    for (let el in locations) {
        queryParams.append('locationId', locations[el]);
    }
    if (nameIncludes !== undefined) queryParams.append('searchText', nameIncludes);
    for (let el in severities) {
        queryParams.append('severity', severities[el]);
    }

    for (let el in selectedNotificationTypes) {
        queryParams.append('notificationMethod', selectedNotificationTypes[el]);
    }

    if (selectEnabled !== undefined) queryParams.append('enabled', selectEnabled);
    if (selectTriggered !== undefined && !displayAll) queryParams.append('triggeredAlarms', selectTriggered);
    return instance.get('/alarmProfiles?' + queryParams.toString(), config);
}

const postAlarmProfile = (token, alarmProfileObject) => {
    const config = setAuthHeader(token);
    return instance.post('/alarmProfiles', alarmProfileObject, config);
}

const getAlarmProfilebyId = (token, alarmProfileId) => {
    const config = setAuthHeader(token);
    return instance.get('/alarmProfiles/' + alarmProfileId, config);
}

const disableAlarmProfile = (token, alarmProfileId) => {
    const config = setAuthHeader(token);
    return instance.put(`/alarmProfiles/${alarmProfileId}/disable`, null, config);
}
const enableAlarmProfile = (token, alarmProfileId) => {
    const config = setAuthHeader(token);
    return instance.put(`/alarmProfiles/${alarmProfileId}/enable`, null, config);
}
const archiveAlarmProfile = (token, alarmProfileId) => {
    const config = setAuthHeader(token);
    return instance.put(`/alarmProfiles/${alarmProfileId}/archive`, null, config);
}

const updateAlarmProfile = (token, alarmId, alarmObject) => {
    const config = setAuthHeader(token);
    return instance.put('/alarmProfiles/' + alarmId, alarmObject, config);

}

const deleteAlarmProfile = (token, alarmProfileId) => {
    const config = setAuthHeader(token);
    return instance.delete('alarmProfiles/' + alarmProfileId, config)
}

const alarmProfilesAPI = {
    getAllAlarmProfiles,
    postAlarmProfile,
    getAlarmProfilebyId,
    disableAlarmProfile,
    enableAlarmProfile,
    archiveAlarmProfile,
    updateAlarmProfile,
    deleteAlarmProfile
}
export default alarmProfilesAPI;