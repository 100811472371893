import React, { useState } from "react";
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from "react-redux";
import { selectMeasurePoints } from "redux/locationsSlice";
import { selectNotificationMethodConf, selectSeverityTypesConf } from "redux/configurationSlice";
import { Autocomplete, Box, Radio, Checkbox, FormControlLabel, RadioGroup, TextField, useMediaQuery, IconButton, InputAdornment, Tooltip } from '@mui/material';
import { SendOutlined } from '@mui/icons-material';

const CustomBox = (props) => {
    return <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px', pb: 1, width: !props.smallScreen ? '30%' : '100%' }}>
        {props.children}
    </Box>
}

export default function AlarmProfileFilters(props) {
    const {
        filterHandler,
        nameSelected,
        selectedLocations,
        selectEnabled,
        selectedNotificationTypes,
        selectInactive,
        selectTriggered,
        severityTypesSelected,
        displayAll
    } = props;

    const locations = useSelector(selectMeasurePoints);
    const smallScreen = useMediaQuery(theme => theme.breakpoints.down("md"));
    const notificationOptions = useSelector(selectNotificationMethodConf);
    const severityTypes = useSelector(selectSeverityTypesConf);
    const intl = useIntl();
    const [nameIncludes, setNameIncludes] = useState("");

    return <Box sx={{ display: 'flex', flexDirection: !smallScreen ? 'row' : 'column', gap: !smallScreen ? '16px' : 0, justifyContent: 'space-evenly' }}>
        <CustomBox smallScreen={smallScreen} >
            <TextField
                size="small"
                onChange={(e) => {
                    const value = e.target.value;
                    if (value === "") filterHandler(value, 'nameIncludes');
                    setNameIncludes(value);
                }}
                id="name-includes-input"
                value={nameIncludes}
                placeholder={intl.formatMessage({ id: "CONTAINS_NAME.PLACEHOLDER" })}
                label={intl.formatMessage({ id: "CONTAINS_NAME" })}
                type="text"
                InputLabelProps={{
                    shrink: nameIncludes !== ""
                }}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <Tooltip title={<FormattedMessage id="SEND" />} arrow>
                                <span>
                                    <IconButton
                                        disabled={nameIncludes === "" || nameIncludes === nameSelected}
                                        aria-label="send-icon"
                                        onClick={() => filterHandler(nameIncludes, 'nameIncludes')}
                                        edge="end"
                                        color="primary"
                                    >
                                        <SendOutlined />
                                    </IconButton>
                                </span>
                            </Tooltip>
                        </InputAdornment>
                    )
                }}
            />
            <Autocomplete
                multiple
                size="small"
                isOptionEqualToValue={(option, value) => option.key === value.key}
                disableCloseOnSelect
                filterSelectedOptions
                onChange={(event, newValue) => filterHandler(newValue.map(el => el.key), 'notificationTypes')}
                id="multiple-limit-tags"
                defaultValue={undefined}
                value={selectedNotificationTypes}
                options={notificationOptions}
                getOptionLabel={(option) => intl.formatMessage({ id: 'NOTIFICATION_OPTION.' + option.value }) || ""}
                renderInput={(params) => (
                    <TextField {...params} label={<FormattedMessage id="NOTIFICATION_TYPE" />} />
                )}
            />
            <Autocomplete
                multiple
                size="small"
                limitTags={3}
                disableCloseOnSelect
                id="multiple-limit-tags"
                options={severityTypes}
                filterSelectedOptions
                value={severityTypesSelected}
                getOptionLabel={(severity) => intl.formatMessage({ id: `SEVERITY.${severity.value}` })}
                renderInput={(params) => (
                    <TextField {...params} label={<FormattedMessage id={"SEVERITY"} />} />
                )}
                onChange={(e, severityType) => filterHandler(severityType.map(el => el.key), 'severityTypes')}
            />
        </CustomBox>
        <CustomBox smallScreen={smallScreen} >
            <Autocomplete
                multiple
                size="small"
                disableCloseOnSelect
                id="multiple-tags"
                options={locations}
                filterSelectedOptions
                value={selectedLocations}
                getOptionLabel={(location) => location.name}
                renderInput={(params) => (
                    <TextField {...params} label={<FormattedMessage id={"ALL_LOCATIONS"} />} />
                )}
                onChange={(e, location) => filterHandler(location.filter(mapedlocation => locations.map(filteredlocation => filteredlocation === mapedlocation)), 'locations')}
            />
            <Box sx={{ mt: 1 }}>
                <RadioGroup row aria-labelledby="profile-enabled-filter" name="profile-enabled-filter" >
                    <FormControlLabel value={true} control={<Radio checked={selectEnabled} onChange={() => filterHandler(true, 'enabled')} />} label={<FormattedMessage id="ENABLED" />} />
                    <FormControlLabel value={false} control={<Radio checked={selectEnabled !== undefined && !selectEnabled} onChange={() => filterHandler(false, 'enabled')} />} label={<FormattedMessage id="DISABLED" />} />
                    <FormControlLabel value={undefined} control={<Radio checked={selectEnabled === undefined} onChange={() => filterHandler(undefined, 'enabled')} />} label={<FormattedMessage id="ALARM.PROFILES.ALL" />} />
                </RadioGroup>
            </Box>
            <Box>
                <RadioGroup row aria-labelledby="profile-triggered-filter" name="profile-triggered-filter" >
                    <FormControlLabel
                        sx={{ display: 'none' }}
                        control={<Checkbox size="small" checked={selectInactive} onChange={() => filterHandler(null, 'inactive')} />}
                        label={<FormattedMessage id="ALARM.PROFILES.INACTIVE" />}
                    />
                    <FormControlLabel
                        control={<Radio checked={!displayAll && selectTriggered} onChange={() => { filterHandler(false, 'displayAll'); filterHandler(true, 'triggered') }} />}
                        label={<FormattedMessage id="TRIGGERED" />}
                    />
                    <FormControlLabel
                        control={<Radio checked={!displayAll && !selectTriggered} onChange={() => { filterHandler(false, 'displayAll'); filterHandler(false, 'triggered') }} />}
                        label={<FormattedMessage id="NOT_TRIGGERED" />}
                    />
                    <FormControlLabel
                        control={<Radio checked={displayAll} onChange={() => filterHandler(null, 'displayAll')} />}
                        label={<FormattedMessage id="ALARM.PROFILES.ALL" />}
                    />
                </RadioGroup>
            </Box>
        </CustomBox>
    </Box >;

}