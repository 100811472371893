import React, { useCallback, useEffect, useState } from 'react';
import { Alert, Box, Tooltip, Card, CardContent, Typography, useMediaQuery } from '@mui/material';
import { useIntl, FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { userSelector, permissionSelector } from 'redux/userSlice';
import { formatDateLocale } from 'utils';
import { selectStatusCodesConf, selectSamplingTypesConf, selectResourcesTypesConf } from 'redux/configurationSlice';
import { selectDateRange } from 'redux/dateRangeSlice';
import { CustomDataGrid, DisplayResourceName, LoadingData, SamplingLogDetails, SideDrawer, StagingSamplesButtons, AlertSnackbar, ComplianceTag } from 'components';
import { AssignmentTurnedInOutlined, AssignmentLateOutlined } from '@mui/icons-material';
import API from 'api';

function StagingSamplesTableContainer(props) {
    const { locations, qualityTypes, sampleTypes, pictureSample } = props;
    const { token } = useSelector(userSelector);
    const selectedDateRange = useSelector(selectDateRange);
    const samplesSampleTypes = useSelector(selectSamplingTypesConf);
    const statusCodes = useSelector(selectStatusCodesConf).COMPLIANCE;
    const resourcesTypes = useSelector(selectResourcesTypesConf);
    const intl = useIntl();
    const [sampleData, setSampleData] = useState(null);
    const [reviewerData, setReviewerData] = useState(null);
    const [approverData, setApproverData] = useState(null);
    const smallScreen = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const [details, setDetails] = useState({});
    const [detailsDrawer, setDetailsDrawer] = useState(false);
    const [openDrawer, setDrawer] = useState(false);
    const [alert, setAlert] = useState({ open: false });
    const onAlertClose = () => setAlert({ ...alert, open: false });
    const approver = useSelector((state) => permissionSelector(state, 'approve-staging-sample'))
    const reviewer = useSelector((state) => permissionSelector(state, 'review-staging-sample'))
    const approverRights = approver ? true : false
    const reviewerRights = reviewer ? true : false

    useEffect(() => {
        setReviewerData(null)
        setApproverData(null)
        setSampleData([])

        if (approverRights) {
            API.samplingLogs.getReviewedStagingSamples(token, locations, selectedDateRange.dateFrom, selectedDateRange.dateTo, sampleTypes, pictureSample)
                .then(items => {
                    if (items.data) {
                        const data = items.data.map(collection => {
                            const sampleType = samplesSampleTypes.find(el => el.key === collection.source.type).value;
                            const status = statusCodes.find(el => el.key === collection.statusCode.COMPLIANCE);
                            return {
                                id: collection._id,
                                locationName: collection.locationId.name,
                                locationId: collection.locationId._id,
                                date: formatDateLocale(collection.timestamp),
                                sampleType: intl.formatMessage({ id: 'SAMPLE_LOG.TYPE_' + sampleType }),
                                quality: intl.formatMessage({ id: status ? `SAMPLE_LOG.STATUS.COMPLIANCE.${status.value}` : 'SAMPLE_LOG.STATUS.NONE' }),
                                sampleNotes: collection.notes ? collection.notes : '',
                                addedBy: collection.source.collector ? collection.source.collector : null,
                                reviewedBy: collection.reviewedBy ? collection.reviewedBy : null,

                            }
                        }).filter(el => el !== null);

                        setApproverData(data);

                    }
                    else setReviewerData([]);
                }).catch(error => {
                    setAlert({ open: true, messageId: (error.data && error.data.id) || "APP.ERROR", severity: "error" });
                    setReviewerData({ error });
                });
        }

        else if (reviewerRights) {
            API.samplingLogs.getStagingSamples(token, locations, selectedDateRange.dateFrom, selectedDateRange.dateTo, sampleTypes, pictureSample)
                .then(items => {
                    if (items.data) {
                        const data = items.data.map(collection => {
                            const sampleType = samplesSampleTypes.find(el => el.key === collection.source.type).value;
                            const status = statusCodes.find(el => el.key === collection.statusCode.COMPLIANCE);
                            return {
                                id: collection._id,
                                locationName: collection.locationId.name,
                                locationId: collection.locationId._id,
                                date: formatDateLocale(collection.timestamp),
                                sampleType: intl.formatMessage({ id: 'SAMPLE_LOG.TYPE_' + sampleType }),
                                quality: intl.formatMessage({ id: status ? `SAMPLE_LOG.STATUS.COMPLIANCE.${status.value}` : 'SAMPLE_LOG.STATUS.NONE' }),
                                sampleNotes: collection.notes ? collection.notes : '',
                                addedBy: collection.source.collector ? collection.source.collector : null,
                                reviewedBy: collection.reviewedBy ? collection.reviewedBy : null,

                            }
                        }).filter(el => el !== null);

                        setReviewerData(data);

                    }
                    else setApproverData([]);


                }).catch(error => {
                    setAlert({ open: true, messageId: (error.data && error.data.id) || "APP.ERROR", severity: "error" });
                    setApproverData({ error });
                });
        }

    }, [approverRights, reviewerRights, token, locations, qualityTypes, sampleTypes, samplesSampleTypes, selectedDateRange, statusCodes, intl, pictureSample]);

    useEffect(() => {
        if (approverRights) setSampleData(approverData)
        else if (reviewerRights) setSampleData(reviewerData)
        else setSampleData([])
    }, [approverRights, approverData, reviewerRights, reviewerData])

    useEffect(() => {
        if (detailsDrawer) {
            try {
                setDetails({
                    title: <FormattedMessage id='SAMPLE_LOG' />,
                    subtitle: <ComplianceTag status={detailsDrawer.statusCode.COMPLIANCE} />,
                    display: <SamplingLogDetails timestampForDialog={detailsDrawer.timestamp} timestamp={formatDateLocale(detailsDrawer.timestamp)} locationName={detailsDrawer.locationName} locationId={detailsDrawer.locationId} logId={detailsDrawer._id} sampleType={detailsDrawer.sampleType} values={detailsDrawer.valuesArray} notes={detailsDrawer.notes} pictures={detailsDrawer.pictures} collector={detailsDrawer.source.collector} staging={true} reviewedBy={detailsDrawer.reviewedBy} labels={detailsDrawer.labels} toggleDrawer={() => setDrawer(false)} setAlert={setAlert} sampleData={sampleData} setSampleData={setSampleData} />
                });
            } catch (err) {
                setAlert({ open: true, messageId: "ERROR.ERROR" });
                setDetails({
                    title: <FormattedMessage id='SAMPLING_LOG_REPORT.ERROR' />,
                    subtitle: '',
                    display: <div />
                });
            }
        }
    }, [sampleData, detailsDrawer])

    const render = useCallback(() => {

        const handleData = (row) => {
            setDrawer(true);
            setDetails({
                title: <FormattedMessage id={'LOADING_DATA'} />,
                subtitle: <FormattedMessage id='WAIT' />,
                display: <LoadingData noText />
            });
            API.samplingLogs.getStagingSamplingDetails(token, row.id)
                .then(({ data }) => {
                    const sampleType = samplesSampleTypes.find(el => el.key === data.source.type).value;
                    const valuesArray = [];
                    for (let index = 0; index < data.measurements.length; index++) {
                        const displayData = data.measurements[index];
                        const resource = resourcesTypes.find(el => el.key === displayData.resourceTypeKey);
                        valuesArray.push({
                            id: resource.key,
                            resource: <DisplayResourceName resource={resource} />,
                            value: displayData.value,
                            unit: resource.unit,
                            resourceTypeKey: resource.key,
                            complianceIndex: displayData.complianceIndex,
                        });
                    }

                    setDetailsDrawer({ ...data, locationName: row.row.locationName, sampleType: sampleType, valuesArray: valuesArray })
                })
                .catch((error) => {
                    setAlert({ open: true, messageId: (error.data && error.data.id) || "APP.ERROR", severity: "error" });
                    setDetails({
                        title: <FormattedMessage id='SAMPLING_LOG_REPORT.ERROR' />,
                        subtitle: '',
                        display: <div />
                    });
                });
        }
        const tableHeaders = [
            {
                field: 'reviewed',
                headerName: intl.formatMessage({ id: "REVIEWED" }),
                renderCell: (row) => row.row.reviewedBy ? (row.row.reviewedBy.username !== "" ?
                    <Tooltip title={<Box><FormattedMessage id={'REVIEWED_BY'} />{row.row.reviewedBy.username}</Box>} arrow>
                        < AssignmentTurnedInOutlined color="success" />
                    </Tooltip> :
                    <AssignmentLateOutlined color="disabled" />) :
                    <AssignmentLateOutlined color="disabled" />,
                ...(!smallScreen && { flex: 0.4 }),
                ...(smallScreen && { minWidth: 100 }),
            },
            {
                field: 'locationName',
                headerName: intl.formatMessage({ id: "SAMPLING_LOG_REPORT.LOCATION_NAME" }),
                ...(!smallScreen && { flex: 1 }),
                ...(smallScreen && { width: 100 }),
            },
            {
                field: 'date',
                headerName: intl.formatMessage({ id: "DATE" }),
                ...(!smallScreen && { flex: 1 }),
                ...(smallScreen && { width: 100 }),
            },
            {
                field: 'quality',
                headerName: intl.formatMessage({ id: "SAMPLING_LOG_REPORT.STATUS" }),
                ...(!smallScreen && { flex: 1 }),
                ...(smallScreen && { minWidth: 130 }),
            },
            {
                field: 'addedBy',
                headerName: intl.formatMessage({ id: "ADDED_BY" }),
                ...(!smallScreen && { flex: 1 }),
                ...(smallScreen && { width: 80 }),
            },
            {
                field: 'actions',
                headerName: intl.formatMessage({ id: 'ACTIONS' }),
                type: 'actions',
                getActions: (row) => [
                    <StagingSamplesButtons sample={row.row} token={token} sampleData={sampleData} setApproverData={setApproverData} setReviewerData={setReviewerData} />
                ],
                ...(!smallScreen && { flex: 1.5 }),
                ...(smallScreen && { width: 320 }),
            }
        ]


        if (sampleData === null) return <span>
            <LoadingData noText />
            <Typography variant='body2'>{<FormattedMessage id='LOADING_DATA' />}</Typography>
        </span>;
        else if (sampleData.error) return <Alert severity="error"><FormattedMessage id='NO_DATA' /></Alert>;
        else if (sampleData.length) return <CustomDataGrid rows={sampleData} columns={tableHeaders} handleData={handleData} />
        else return <Alert severity="warning"><FormattedMessage id='NO_DATA_TIME_PERIOD' /></Alert>;
    }, [sampleData, intl, resourcesTypes, samplesSampleTypes, token, smallScreen]);

    return <>
        <AlertSnackbar open={alert.open} onClose={onAlertClose} severity={alert.severity} messageId={alert.messageId} />
        <Card>
            <CardContent>
                {render()}
                <SideDrawer state={details} open={openDrawer} toggleDrawer={() => setDrawer(false)} />
            </CardContent>
        </Card>
    </>;
}

export default StagingSamplesTableContainer;