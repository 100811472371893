import { FooterIRI } from 'components';
import { useIntl } from 'react-intl';
import './aboutIRI.scss';

function AboutIRI() {
    const intl = useIntl();
    return (
        <div className='about-page-body'>
            <div className='about-image-container'>
                <img className='about-shutterstock-image' src={window.innerWidth > 960 ? window.location.origin + '/images/IRI/About/shutterstock-image.png' : (window.innerHeight > window.innerWidth ? window.location.origin + '/images/IRI/About/shutterstock-image-mobile.png' : window.location.origin + '/images/IRI/About/shutterstock-image.png')} alt='shutterstock' />
                <hr className='about-separator-line-image' />
                <div className='about-image-caption'>{intl.formatMessage({ id: 'ABOUT_PAGE_IRI.IMAGE_CAPTION' }).toUpperCase()}</div>
            </div>
            <div className='about-page-container'>
                <hr className='about-container-separator-line-one' />
                <div className='digital-transformation-title'>{intl.formatMessage({ id: 'ABOUT_PAGE_IRI.DIGITAL_TRASFORMATION_TITLE' })}</div>
                <div className='digital-transformation-description'>{intl.formatMessage({ id: 'ABOUT_PAGE_IRI.DIGITAL_TRASFORMATION_DESCRIPTION' })}</div>
                <hr className='about-container-separator-line-two' />
                <div className='implementation-reasons-title'>{intl.formatMessage({ id: 'ABOUT_PAGE_IRI.IMPLEMENTATION_REASONS' })}</div>
                <div className='implementation-reasons-blocks'>
                    <div className='implementation-reasons-block'>
                        <img className='implementation-reasons-block-image-one' src={window.location.origin + '/images/IRI/About/wifi.png'} alt='wifi' />
                        <div className='implementation-reasons-block-text'>{intl.formatMessage({ id: 'ABOUT_PAGE_IRI.BLOCK_TEXT_ONE' })}</div>
                    </div>
                    <div className='implementation-reasons-block'>
                        <img className='implementation-reasons-block-image-two' src={window.location.origin + '/images/IRI/About/weather.png'} alt='weather' />
                        <div className='implementation-reasons-block-text'> {intl.formatMessage({ id: 'ABOUT_PAGE_IRI.BLOCK_TEXT_TWO' })}</div>
                    </div>
                    <div className='implementation-reasons-block'>
                        <img className='implementation-reasons-block-image-three' src={window.location.origin + '/images/IRI/About/volume.png'} alt='volume' />
                        <div className='implementation-reasons-block-text'> {intl.formatMessage({ id: 'ABOUT_PAGE_IRI.BLOCK_TEXT_THREE' })}</div>
                    </div>
                    <div className='implementation-reasons-block'>
                        <img className='implementation-reasons-block-image-four' src={window.location.origin + '/images/IRI/About/water-health.png'} alt='water-health' />
                        <div className='implementation-reasons-block-text'>{intl.formatMessage({ id: 'ABOUT_PAGE_IRI.BLOCK_TEXT_FOUR' })}</div>
                    </div>
                    <div className='implementation-reasons-block'>
                        <img className='implementation-reasons-block-image-five' src={window.location.origin + '/images/IRI/About/parasite.png'} alt='parasite' />
                        <div className='implementation-reasons-block-text'>{intl.formatMessage({ id: 'ABOUT_PAGE_IRI.BLOCK_TEXT_FIVE' })}</div>
                    </div>
                    <div className='implementation-reasons-block'>
                        <img className='implementation-reasons-block-image-six' src={window.location.origin + '/images/IRI/About/information.png'} alt='information' />
                        <div className='implementation-reasons-block-text'>{intl.formatMessage({ id: 'ABOUT_PAGE_IRI.BLOCK_TEXT_SIX' })}</div>
                    </div>
                    <div className='implementation-reasons-block'>
                        <img className='implementation-reasons-block-image-seven' src={window.location.origin + '/images/IRI/About/system.png'} alt='system' />
                        <div className='implementation-reasons-block-text'>{intl.formatMessage({ id: 'ABOUT_PAGE_IRI.BLOCK_TEXT_SEVEN' })}</div>
                    </div>
                </div>
                <hr className='about-container-separator-line-three' />
                <div className='project-consortium-title'>{intl.formatMessage({ id: 'ABOUT_PAGE_IRI.PROJECT_CONSORTIUM_TITLE' })}</div>
                <div className='project-consortium-content'>{intl.formatMessage({ id: 'ABOUT_PAGE_IRI.PROJECT_CONSORTIUM_DESCRIPTION' })}</div>
                <div className='partners-logo'>
                    <img className='partners-logo-ericsson' src={window.location.origin + '/images/IRI/About/ericsson-logo.png'} alt='ericsson' />
                    <img className='partners-logo-gauss' src={window.location.origin + '/images/IRI/About/gauss-logo.png'} alt='gauss' />
                    <img className='partners-logo-hzjz' src={window.location.origin + '/images/IRI/About/hzjz-logo.png'} alt='hzjz' />
                    <img className='partners-logo-telemetris' src={window.location.origin + '/images/IRI/About/telemetris-logo.png'} alt='telemetris' />
                </div>
                <hr className='about-container-separator-line-three' />
                <div className='goals-expected-result-title'>{intl.formatMessage({ id: 'ABOUT_PAGE_IRI.GOALS_EXPECTED_RESULTS_TITLE' })}</div>
                <div className='goals-expected-result-content'>{intl.formatMessage({ id: 'ABOUT_PAGE_IRI.GOALS_EXPECTED_RESULTS_DESCRIPTION' })}</div>
                <hr className='about-container-separator-line-three' />
                <div className='links-title'>{intl.formatMessage({ id: 'ABOUT_PAGE_IRI.LINKS_TITLE' })}</div>
                <div className='links-content'>
                    <div className='links-description' >
                        <a href='https://strukturnifondovi.hr/'>
                            {intl.formatMessage({ id: 'ABOUT_PAGE_IRI.LINKS_ONE' })}
                        </a>
                    </div>
                    <div className='links-description' >
                        <a href='https://strukturnifondovi.hr/natjecaji/povecanje-razvoja-novih-proizvoda-i-usluga-koji-proizlaze-iz-aktivnosti-istrazivanja-i-razvoja-faza-ii/'>
                            {intl.formatMessage({ id: 'ABOUT_PAGE_IRI.LINKS_TWO' })}
                        </a>
                    </div>
                    <div className='links-description' >
                        <a href='https://www.ericsson.hr/-/20210719-waterq'>
                            {intl.formatMessage({ id: 'ABOUT_PAGE_IRI.LINKS_THREE' })}
                        </a>
                    </div>
                    <div className='links-description' >
                        <a href='https://www.ericsson.hr/documents/20181/232209/Komunikacije_3_2021.pdf'>
                            {intl.formatMessage({ id: 'ABOUT_PAGE_IRI.LINKS_FOUR' })}
                        </a>
                    </div>
                    <div className='links-description' >
                        <a href={window.location.origin + '/documents/waterq_brochure.pdf'} download='waterq_brochure'>
                            {intl.formatMessage({ id: 'ABOUT_PAGE_IRI.LINKS_FIVE' })}
                        </a>
                    </div>
                </div>
            </div>
            <div className='about-finances'>
                <div className='about-finances-container'>
                    <div className='about-finances-block'>
                        <div className='about-finances-text'>
                            {intl.formatMessage({ id: 'ABOUT_PAGE_IRI.FINANCES_TEXT_ONE' })}
                        </div>
                        <div className='about-finances-numbers'>
                            {intl.formatMessage({ id: 'ABOUT_PAGE_IRI.FINANCES_NUMBERS_ONE' })}
                        </div>
                    </div>
                    <div className='about-finances-block'>
                        <div className='about-finances-text'>
                            {intl.formatMessage({ id: 'ABOUT_PAGE_IRI.FINANCES_TEXT_TWO' })}
                        </div>
                        <div className='about-finances-numbers'>
                            {intl.formatMessage({ id: 'ABOUT_PAGE_IRI.FINANCES_NUMBERS_TWO' })}
                        </div>
                    </div>
                    <div className='about-finances-block'>
                        <div className='about-finances-text'>
                            {intl.formatMessage({ id: 'ABOUT_PAGE_IRI.FINANCES_TEXT_THREE' })}
                        </div>
                        <div className='about-finances-numbers'>
                            {intl.formatMessage({ id: 'ABOUT_PAGE_IRI.FINANCES_NUMBERS_THREE' })}
                        </div>
                    </div>
                </div>
            </div>
            <FooterIRI />
        </div>
    )
}

export default AboutIRI;
