import { useCallback, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Box, Card, CardHeader, CardMedia, CardContent, IconButton, Tooltip, Alert, AlertTitle } from "@mui/material";
import { MapOutlined, DescriptionOutlined, PhotoOutlined } from '@mui/icons-material';
import { LocationMapTab } from 'components';

export default function LocationDetailsTabs(props) {
    const { location } = props;
    const { locale } = useIntl();
    const [content, setContent] = useState("map");
    const [poiInput, setPoiInput] = useState(false);

    const renderContent = useCallback(() => {
        switch (content) {
            case "desc":
                return <CardContent>{location.description[locale]}</CardContent>
            case "pic":
                return <CardMedia
                    component="img"
                    image={process.env.REACT_APP_API_BASE_URL + "/" + location.picture}
                    alt="location-picture"
                />
            case "map":
                return <Box>
                    <LocationMapTab location={location} poiInput={poiInput} setPoiInput={setPoiInput} />
                </Box>
            default:
                return null;
        }
    }, [content, location, locale, poiInput, setPoiInput]);


    return <Card sx={{ width: '100%' }}>
        {!poiInput ?
            <CardHeader title={location.name} action={[
                <Tooltip key="map" title={<FormattedMessage id="SHOW_POSITION" />} arrow>
                    <IconButton onClick={() => setContent("map")}>
                        <MapOutlined color={content === "map" ? "primary" : "default"} fontSize="small" />
                    </IconButton>
                </Tooltip>,
                <Tooltip key="picture" title={<FormattedMessage id="VIEW_PICTURE" />} arrow>
                    <IconButton onClick={() => setContent("pic")}>
                        <PhotoOutlined color={content === "pic" ? "primary" : "default"} fontSize="small" />
                    </IconButton>
                </Tooltip>,
                location.description[locale] && <Tooltip key="description" title={<FormattedMessage id="VIEW_DESCRIPTION" />} arrow>
                    <IconButton onClick={() => setContent("desc")}>
                        <DescriptionOutlined color={content === "desc" ? "primary" : "default"} fontSize="small" />
                    </IconButton>
                </Tooltip>
            ]} />
            : <Alert className="MuiCardHeader-root" severity="info" onClose={() => setPoiInput(false)}>
                <AlertTitle><FormattedMessage id="INFO" /></AlertTitle>
                <FormattedMessage id="ADD_POI" />
            </Alert>
        }
        {renderContent()}
    </Card>;
}