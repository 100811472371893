import React, { useState } from 'react';
import { useSelector } from "react-redux";
import { selectThemeMode, selectFontSize, selectFontWeight, selectLanguage } from 'redux/appSlice';
import { AppBar, MenuDrawer, Copyright } from 'components';
import themes from "../themes/Themes";
import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, Box, Toolbar, Container, useTheme, useMediaQuery } from '@mui/material';
import { enUS, hrHR } from '@mui/material/locale';

const APP_TITLE = process.env.REACT_APP_TITLE;
const SHORT_APP_TITLE = process.env.REACT_APP_TITLE_ACRONYM;

export default function DefaultLayout(props) {
    const theme = useTheme();
    const smallMediaScreen = useMediaQuery(theme.breakpoints.up("sm"));
    const mode = useSelector(selectThemeMode);
    const fontSize = useSelector(selectFontSize);
    const fontWeight = useSelector(selectFontWeight);
    const lang = useSelector(selectLanguage);
    const { page } = props;
    const [open, setOpen] = useState(false);
    const toggleDrawer = () => {
        setOpen(!open);
    };

    const currentLocale = lang === "hr" ? hrHR : enUS;
    return (
        <ThemeProvider theme={themes[mode](fontSize, fontWeight, currentLocale)}>
            <Box className={mode} sx={{ display: 'flex' }}>
                <CssBaseline />
                <AppBar open={open} title={smallMediaScreen ? APP_TITLE : SHORT_APP_TITLE} toggleDrawer={toggleDrawer} />
                <MenuDrawer open={open} selectedItem={page.type.name} admin />
                <Box
                    className={theme.palette.mode}
                    component="main"
                    sx={{
                        backgroundColor: (theme) =>
                            theme.palette.mode === 'light'
                                ? theme.palette.grey[100]
                                : theme.palette.grey[900],
                        display: 'flex',
                        flexDirection: 'column',
                        flexGrow: 1,
                        height: '100vh',
                        overflow: 'auto',
                    }}
                >
                    <Toolbar />
                    <Container maxWidth={false} sx={{ flexGrow: 1 }}>
                        {page}
                    </Container>
                    <Copyright />
                </Box>
            </Box>
        </ThemeProvider>
    );
};