import React, { useState } from "react";
import { useSelector } from "react-redux";
import { selectLocations } from 'redux/locationsSlice';
import { permissionSelector } from "redux/userSlice";
import { FormattedMessage } from "react-intl";
import { LocationDetailsDialogForm, LocationGroupCard } from "components";
import { Grid, IconButton, Tooltip, Card } from "@mui/material";
import { AddOutlined } from "@mui/icons-material";

export default function LocationGroupsCardView() {
    const locations = useSelector(selectLocations);
    const orgAdmin = useSelector((state) => permissionSelector(state, 'create-group'));
    const [openFormDialog, setOpenFormDialog] = useState(false);
    const [successMsg, setSuccessMsg] = useState("");

    const handleClickOpenForm = () => {
        setOpenFormDialog(true);
        setSuccessMsg("");
    };

    const handleCloseForm = () => {
        setOpenFormDialog(false);
    };

    return (
        <Grid container spacing={2} >
            {
                locations.map((location, i) => (
                    <Grid key={i} item xs={12} sm={12} md={6} lg={4} sx={{ display: 'flex' }}>
                        <LocationGroupCard location={location} />
                    </Grid>
                ))
            }
            {orgAdmin ? <Grid key="addLocationGroupIcon" item xs={12} sm={12} md={6} lg={4} sx={{ display: 'flex' }}>
                <Card sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                    <Tooltip title={<FormattedMessage id="ADD_LOCATION_GROUP" />} arrow placement="bottom">
                        <IconButton color="primary" sx={{ m: 'auto' }} key="addLocationGroup" onClick={handleClickOpenForm}><AddOutlined fontSize="large" /></IconButton>
                    </Tooltip>
                </Card>
            </Grid> : null}
            <LocationDetailsDialogForm
                openFormDialog={openFormDialog}
                handleCloseForm={handleCloseForm}
                successMsg={successMsg}
                setSuccessMsg={setSuccessMsg}
            />
        </Grid>
    );
}