import { useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { DefaultLayout, PublicLayout, EmptyState } from 'layouts';
import { AlarmsProfileView, SingleAlarmProfileView, LoginRequired } from 'components';
import { useSelector } from 'react-redux';
import { userSelector } from 'redux/userSlice';



function AlarmsProfilePage(props) {
    const alarmProfileId = useParams().alarmProfileId;
    const [searchparams] = useSearchParams();
    const editFlag = searchparams.get("edit");

    if (!alarmProfileId) return <EmptyState component={<AlarmsProfileView />} />;
    else if (alarmProfileId) return <EmptyState component={<SingleAlarmProfileView alarmId={alarmProfileId} editFlag={editFlag} />} />;
}

export default function AlarmsProfilePageComponent(props) {
    const { isSuccess: loggedIn } = useSelector(userSelector);

    if (loggedIn) return <DefaultLayout page={<AlarmsProfilePage />} />;
    else return <PublicLayout page={<LoginRequired />} />;
};