import { Container, Card, CardActions, CardContent, CardHeader, useMediaQuery, Typography } from "@mui/material";


export default function CustomCardReport(props) {
    const { actions, title, subtitle, headerAction, children, avatar } = props;
    const smallScreen = useMediaQuery(theme => theme.breakpoints.down("sm"));

    return <Card sx={{ width: smallScreen ? '100%' : '60%', mx: 'auto', position: 'relative' }}>
        <Container disableGutters={smallScreen}>
            <CardHeader title={<Typography variant="h5" marginBottom="1rem">{avatar} {title}</Typography>} subheader={subtitle} action={headerAction} />
        </Container>
        <CardContent>
            <Container disableGutters={smallScreen}>
                {children}
            </Container>
        </CardContent>
        <CardActions>
            {actions}
        </CardActions>
    </Card>
};