import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { DataGrid, GridToolbarContainer, GridToolbarColumnsButton, GridToolbarFilterButton, GridToolbarDensitySelector, GridToolbarExport } from '@mui/x-data-grid';

let defaultRowsPerPageOptions = [25, 50, 75, 100];

export default function CustomDataGrid(props) {
    const { rows, columns, setSelectedRows, handleData, rowsPerPage, enableExport, customCellClass, rowCount, page, onPageChange, onPageSizeChange, size, paginationMode } = props;
    const [pageSize, setPageSize] = useState(rowsPerPage ? rowsPerPage[0] : defaultRowsPerPageOptions[0])
    
    const intl = useIntl();

    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <GridToolbarColumnsButton />
                <GridToolbarFilterButton />
                <GridToolbarDensitySelector />
                {enableExport && !!rows.length ? <GridToolbarExport /> : null}
            </GridToolbarContainer>
        )
    }

    return (
        <DataGrid
            sx={{
                "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                    outline: "none" // removes outline on row cell click
                },
                cursor: 'pointer'
            }}
            getCellClassName={customCellClass}
            autoHeight
            pagination
            paginationMode={paginationMode ? paginationMode : 'client'}
            page={page}
            rowCount={rowCount}
            rows={rows}
            columns={columns}
            getRowId={(row) => row.id}
            pageSize={size ? size : pageSize}
            rowsPerPageOptions={rowsPerPage ? rowsPerPage : defaultRowsPerPageOptions}
            onPageSizeChange={onPageSizeChange ? onPageSizeChange: setPageSize}
            onPageChange={onPageChange}
            checkboxSelection={!!setSelectedRows}
            disableSelectionOnClick
            disableColumnMenu
            editMode="row"
            onSelectionModelChange={(ids) => {
                if (!setSelectedRows) return
                const selectedIds = new Set(ids);
                const selectedRows = rows.filter((row) =>
                    selectedIds.has(row.id),
                );
                setSelectedRows(selectedRows);
            }}
            experimentalFeatures={{ newEditingApi: true }}
            components={{
                Toolbar: CustomToolbar
            }}
            onRowClick={(row, event) => {
                if (!handleData) return
                handleData(row)
            }}
            localeText={{
                //root
                noRowsLabel: intl.formatMessage({ id: 'ROW.NO_ROWS' }),
                noResultsOverlayLabel: intl.formatMessage({ id: 'NO_RESULTS' }),
                errorOverlayDefaultLabel: intl.formatMessage({ id: 'ROW.ERROR' }),
                //columns5
                toolbarColumns: intl.formatMessage({ id: 'COLUMNS' }),
                columnsPanelTextFieldLabel: intl.formatMessage({ id: 'COLUMNS_FIND' }),
                columnsPanelTextFieldPlaceholder: intl.formatMessage({ id: 'COLUMNS_TITLE' }),
                columnsPanelDragIconLabel: intl.formatMessage({ id: 'REORDER' }),
                columnsPanelShowAllButton: intl.formatMessage({ id: 'SHOW_ALL' }),
                columnsPanelHideAllButton: intl.formatMessage({ id: 'HIDE_ALL' }),
                checkboxSelectionHeaderName: intl.formatMessage({ id: 'CHECKBOX_SELECTION' }),
                footerRowSelected: (count) =>
                    count !== 1
                        ? `${intl.formatMessage({ id: 'ROW.SELECTED_ROWS' })} : ${count.toLocaleString()} `
                        : `${intl.formatMessage({ id: 'ROW.SELECTED_ROWS' })} : ${count.toLocaleString()} `,
                //filter
                toolbarFilters: intl.formatMessage({ id: 'FILTER' }),
                toolbarFiltersLabel: intl.formatMessage({ id: 'FILTER_SHOW' }),
                toolbarFiltersTooltipHide: intl.formatMessage({ id: 'FILTER_HIDE' }),
                toolbarFiltersTooltipShow: intl.formatMessage({ id: 'FILTER_SHOW' }),
                filterPanelAddFilter: intl.formatMessage({ id: 'FILTER_ADD' }),
                filterPanelDeleteIconLabel: intl.formatMessage({ id: 'DELETE' }),
                filterPanelColumns: intl.formatMessage({ id: 'COLUMNS' }),
                filterPanelInputLabel: intl.formatMessage({ id: 'VALUE' }),
                filterPanelInputPlaceholder: intl.formatMessage({ id: 'FILTER_VALUE' }),
                filterOperatorContains: intl.formatMessage({ id: 'OPERATOR_CONTAINS' }),
                filterOperatorEquals: intl.formatMessage({ id: 'EQUALS' }),
                filterOperatorStartsWith: intl.formatMessage({ id: 'STARTS_WITH' }),
                filterOperatorEndsWith: intl.formatMessage({ id: 'ENDS_WITH' }),
                filterOperatorIs: intl.formatMessage({ id: 'IS' }),
                filterOperatorNot: intl.formatMessage({ id: 'IS_NOT' }),
                filterOperatorAfter: intl.formatMessage({ id: 'IS_AFTER' }),
                filterOperatorOnOrAfter: intl.formatMessage({ id: 'IS_ON_OR_AFTER' }),
                filterOperatorBefore: intl.formatMessage({ id: 'IS_BEFORE' }),
                filterOperatorOnOrBefore: intl.formatMessage({ id: 'IS_ON_OR_BEFORE' }),
                filterOperatorIsEmpty: intl.formatMessage({ id: 'IS_EMPTY' }),
                filterOperatorIsNotEmpty: intl.formatMessage({ id: 'IS_NOT_EMPTY' }),
                filterOperatorIsAnyOf: intl.formatMessage({ id: 'IS_ANY_OF' }),
                toolbarFiltersTooltipActive: (count) =>
                    count !== 1 ? `${intl.formatMessage({ id: 'FILTER_ACTIVE' })} ${count}` : `${intl.formatMessage({ id: 'FILTER_ACTIVE' })} ${count}`,
                //density
                toolbarDensity: intl.formatMessage({ id: 'DENSITY' }),
                toolbarDensityLabel: intl.formatMessage({ id: 'DENSITY' }),
                toolbarDensityCompact: intl.formatMessage({ id: 'COMPACT' }),
                toolbarDensityStandard: intl.formatMessage({ id: 'STANDARD' }),
                toolbarDensityComfortable: intl.formatMessage({ id: 'COMFORTABLE' }),
                //export
                toolbarExport: intl.formatMessage({ id: 'EXPORT' }),
                toolbarExportLabel: intl.formatMessage({ id: 'EXPORT' }),
                toolbarExportCSV: intl.formatMessage({ id: 'DOWNLOAD_CSV' }),
                toolbarExportPrint: intl.formatMessage({ id: 'PRINT' }),
            }}
        />

    );
}