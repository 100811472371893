import React from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { selectAuthDone } from 'redux/appSlice';
import { setLanguage } from 'redux/appSlice';
import { useNavigate, useLocation } from 'react-router-dom';
import { PageLoading, PrivacyPolicyDialog } from 'components';
import './layoutIRI.scss';

const LayoutIRI = (props) => {
    const { children, language } = props;
    const path = useLocation().pathname.split('/');
    const selectedPath = path[1];
    const intl = useIntl();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const authDone = useSelector(selectAuthDone);


    const changeLanguage = (lang) => {
        dispatch(setLanguage(lang));
    }

    if (!authDone) return <PageLoading messageId="KEYCLOAK" />
    else if (window.innerWidth < 960) {
        return (
            <div className='layout-menu-container'>
                <div onClick={() => navigate('/homeIRI')} className='waterQ-logo-container'>
                    <img className='waterQ-logo' src={window.location.origin + '/images/IRI/Menu/waterQ-logo-IRI.png'} alt='waterQ-logo' />
                </div>
                <div className='menu-container'>
                    <div className='tabs-container'>
                        <img src={window.location.origin + '/icons/MenuIcons/about.png'} onClick={() => navigate('/aboutIRI')} className={selectedPath === 'aboutIRI' ? 'waterQ-project-details-selected' : 'waterQ-project-details'} alt='about'></img>
                        <img src={window.location.origin + '/icons/MenuIcons/location.png'} onClick={() => navigate('/map')} className='waterQ-locations' alt='location'></img>
                        <img src={window.location.origin + '/icons/MenuIcons/contact.png'} onClick={() => navigate('/contactsIRI')} className={selectedPath === 'contactsIRI' ? 'waterQ-contacts-selected' : 'waterQ-contacts'} alt='contacts'></img>
                    </div>
                    <div className='language-selector-container'>
                        <div className='select-language'>{intl.formatMessage({ id: 'LANDING_PAGE_IRI.LANGUAGE_SELECTOR' })}</div>
                        <div className='language-list'>
                            <div className={language === 'hr' ? 'croatian-lang-selected' : 'croatian-lang'} onClick={() => changeLanguage('hr')}>{intl.formatMessage({ id: 'LANGUAGE.hr' })}</div>
                            <div className={language === 'en' ? 'english-lang-selected' : 'english-lang'} onClick={() => changeLanguage('en')}>{intl.formatMessage({ id: 'LANGUAGE.en' })}</div>
                        </div>
                    </div>
                </div>
                {children}
                <PrivacyPolicyDialog/>
            </div >
        )
    }

    else {
        return (
            <div className='layout-menu-container'>
                <div className='menu-container'>
                    <div onClick={() => navigate('/homeIRI')} className='waterQ-logo-container'>
                        <img className='waterQ-logo' src={window.location.origin + '/images/IRI/Menu/waterQ-logo-IRI.png'} alt='waterQ-logo' />
                    </div>
                    <div className='tabs-container'>
                        <div onClick={() => navigate('/aboutIRI')} id='project-details' className={selectedPath === 'aboutIRI' ? 'waterQ-project-details-selected' : 'waterQ-project-details'}>{intl.formatMessage({ id: 'LANDING_PAGE_IRI.MENU_PROJECT' }).toUpperCase()}</div>
                        <div onClick={() => navigate('/map')} id='project-locations' className='waterQ-locations'>{intl.formatMessage({ id: 'LANDING_PAGE_IRI.MENU_LOCATIONS' }).toUpperCase()}</div>
                        <div onClick={() => navigate('/contactsIRI')} id='project-contacts' className={selectedPath === 'contactsIRI' ? 'waterQ-contacts-selected' : 'waterQ-contacts'}>{intl.formatMessage({ id: 'LANDING_PAGE_IRI.MENU_CONTACTS' }).toUpperCase()}</div>
                    </div>
                    <div className='language-selector-container'>
                        <div className='select-language'>{intl.formatMessage({ id: 'LANDING_PAGE_IRI.LANGUAGE_SELECTOR' })}</div>
                        <div className='language-list'>
                            <img className='croatian-flag' src={window.location.origin + '/icons/Language/croatia-icon.png'} alt='croatian-flag' />
                            <div className={language === 'hr' ? 'croatian-lang-selected' : 'croatian-lang'} onClick={() => changeLanguage('hr')}>{intl.formatMessage({ id: 'LANGUAGE.hr' })}</div>
                            <img className='uk-flag' src={window.location.origin + '/icons/Language/uk-icon.png'} alt='uk-flag' />
                            <div className={language === 'en' ? 'english-lang-selected' : 'english-lang'} onClick={() => changeLanguage('en')}>{intl.formatMessage({ id: 'LANGUAGE.en' })}</div>
                        </div>
                    </div>
                    <div className='outer-logo-container'>
                        <img className='EU-logo' src={window.location.origin + '/images/IRI/Menu/Fondovi-EU-logo-IRI.png'} alt='EU-logo' />
                        <img className='Linkedin-logo' src={window.location.origin + '/images/IRI/Menu/Linkedin-logo-IRI.png'} alt='Linkedin-logo' />
                        <img className='YT-logo' src={window.location.origin + '/images/IRI/Menu/YT-icon-IRI.png'} alt='YT-icon' />
                    </div>
                </div>
                {children}
            </div >
        )
    }
}

export default LayoutIRI;