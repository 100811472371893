import React, { useCallback } from 'react';
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import { AspectRatio } from 'react-aspect-ratio';
import "swiper/css";
import "swiper/css/navigation";
import "./styles.css";

function PictureGallery(props) {
    const { pictures, maxWidth } = props;

    const renderGallery = useCallback(() => {
        const key = pictures && pictures.length ? pictures[0].name : null;
        if (key === null || key === undefined) return null;
        return <Swiper style={maxWidth && { maxWidth: maxWidth }} key={key} autoHeight navigation={true} modules={[Navigation]} className="mySwiper">
            {pictures.map((image, i) => (
                <SwiperSlide key={i}>
                    <AspectRatio ratio="1">
                        <img src={image.url} alt={image.name} style={{ maxHeight: '40vh' }} />
                    </AspectRatio>
                </SwiperSlide>
            ))}
        </Swiper>;
    }, [pictures, maxWidth]);

    return renderGallery();
}

export default PictureGallery;