import instance, { setAuthHeader } from './axiosEnv';
import { localISOString } from 'utils';

const getSamplingNumbers = (token, locationId = '', dateFrom = null, dateTo = null, qualityStatuses = '', sampleTypes = '', aggregation = null, collectors = '', samplePictures = false) => {
    const config = setAuthHeader(token);
    const queryParams = new URLSearchParams();
    queryParams.append('dateFrom', localISOString(dateFrom));
    queryParams.append('dateTo', localISOString(dateTo));
    queryParams.append('samplePictures', samplePictures);
    for (let el in locationId) {
        queryParams.append('locationId', locationId[el]);
    }
    for (let el in qualityStatuses) {
        queryParams.append('qualityStatus', qualityStatuses[el])
    }
    for (let el in sampleTypes) {
        queryParams.append('sampleType', sampleTypes[el])
    }
    for (let el in collectors) {
        queryParams.append('collector', collectors[el])
    }
    queryParams.append('aggregation', aggregation);

    return instance.get('/samplingLogs/samplingNumber?' + queryParams.toString(), config);
}

const getQualityLevelDistribution = (token, locationId = '', dateFrom = null, dateTo = null, qualityStatuses = '', sampleTypes = '', collectors = '', samplePictures = false) => {
    const config = setAuthHeader(token);
    const queryParams = new URLSearchParams();
    queryParams.append('dateFrom', localISOString(dateFrom));
    queryParams.append('dateTo', localISOString(dateTo));
    queryParams.append('samplePictures', samplePictures);
    for (let el in locationId) {
        queryParams.append('locationId', locationId[el]);
    }
    for (let el in qualityStatuses) {
        queryParams.append('qualityStatus', qualityStatuses[el])
    }
    for (let el in sampleTypes) {
        queryParams.append('sampleType', sampleTypes[el])
    }
    for (let el in collectors) {
        queryParams.append('collector', collectors[el])
    }

    return instance.get('/samplingLogs/qualityLevels?' + queryParams.toString(), config);
}

const getSamplingValues = (token, locationId = '', dateFrom = null, dateTo = null, qualityStatuses = '', sampleTypes = '', samplePictures = false, page, size, collectors = '') => {
    const config = setAuthHeader(token);
    const queryParams = new URLSearchParams();
    queryParams.append('dateFrom', new Date(dateFrom).toISOString());
    queryParams.append('dateTo', new Date(dateTo).toISOString());
    queryParams.append('samplePictures', samplePictures);
    for (let el in locationId) {
        queryParams.append('locationId', locationId[el]);
    }
    for (let el in qualityStatuses) {
        queryParams.append('qualityStatus', qualityStatuses[el])
    }
    for (let el in sampleTypes) {
        queryParams.append('sampleType', sampleTypes[el])
    }
    queryParams.append('page', page);
    queryParams.append('size', size);
    for (let el in collectors) {
        queryParams.append('collector', collectors[el])
    }

    return instance.get('/samplingLogs?' + queryParams.toString(), config);
}

const getSampleTypeDistribution = (token, locationId = '', dateFrom = null, dateTo = null, qualityStatuses = '', sampleTypes = '', collectors = '', samplePictures = false) => {
    const config = setAuthHeader(token);
    const queryParams = new URLSearchParams();
    queryParams.append('dateFrom', localISOString(dateFrom));
    queryParams.append('dateTo', localISOString(dateTo));
    queryParams.append('samplePictures', samplePictures);
    for (let el in locationId) {
        queryParams.append('locationId', locationId[el]);
    }
    for (let el in qualityStatuses) {
        queryParams.append('qualityStatus', qualityStatuses[el])
    }
    for (let el in sampleTypes) {
        queryParams.append('sampleType', sampleTypes[el])
    }
    for (let el in collectors) {
        queryParams.append('collector', collectors[el])
    }

    return instance.get('/samplingLogs/sampleTypes?' + queryParams.toString(), config);
}

const getSamplingDetails = (token, samplingLogId) => {
    const config = setAuthHeader(token);
    return instance.get('/samplingLogs/details/' + samplingLogId, config);
}

const getStagingSamples = (token, locationId = '', dateFrom = null, dateTo = null, sampleTypes = '', samplePictures = false) => {
    const config = setAuthHeader(token);
    const queryParams = new URLSearchParams();
    queryParams.append('dateFrom', localISOString(dateFrom));
    queryParams.append('dateTo', localISOString(dateTo));
    queryParams.append('samplePictures', samplePictures);

    for (let el in locationId) {
        queryParams.append('locationId', locationId[el]);
    }
    for (let el in sampleTypes) {
        queryParams.append('sampleType', sampleTypes[el])
    }
    return instance.get('/samplingLogs/staging/review?' + queryParams.toString(), config);
}


const getReviewedStagingSamples = (token, locationId = '', dateFrom = null, dateTo = null, sampleTypes = '', samplePictures = false) => {
    const config = setAuthHeader(token);
    const queryParams = new URLSearchParams();
    queryParams.append('dateFrom', localISOString(dateFrom));
    queryParams.append('dateTo', localISOString(dateTo));
    queryParams.append('samplePictures', samplePictures);

    for (let el in locationId) {
        queryParams.append('locationId', locationId[el]);
    }
    for (let el in sampleTypes) {
        queryParams.append('sampleType', sampleTypes[el])
    }

    return instance.get('/samplingLogs/staging/approve?' + queryParams.toString(), config);
}

const getStagingSamplingDetails = (token, samplingLogId) => {
    const config = setAuthHeader(token);
    return instance.get('/samplingLogs/staging/details/' + samplingLogId, config);
}

const approveStagingSample = (token, samplingLogId) => {
    const config = setAuthHeader(token);
    return instance.put('/samplingLogs/staging/approve/' + samplingLogId, undefined, config);
}

const reviewStagingSample = (token, samplingLogId) => {
    const config = setAuthHeader(token);
    return instance.put('/samplingLogs/staging/review/' + samplingLogId, undefined, config);
}

const rejectStagingSample = (token, samplingLogId) => {
    const config = setAuthHeader(token);
    return instance.delete('/samplingLogs/staging/reject/' + samplingLogId, config);
}

const postSamples = (token, collector, locationId, values, labels, files) => {
    const config = setAuthHeader(token);
    const data = [];
    const dataKeys = Object.keys(values);
    for (let index = 0; index < dataKeys.length; index++) {
        const key = dataKeys[index];
        if (key === 'notes' || key === 'timestamp' || values[key] === "") continue;
        else {
            if (typeof values[key] === 'object') {
                if (values[key].value === '') continue
                data.push({ resourceTypeKey: key, value: { value: values[key].value, description: values[key].description } })
            }
            else
                data.push({ resourceTypeKey: key, value: values[key] })
        }
    }

    const formData = new FormData();

    formData.append('samplingType', 3);
    formData.append('locationId', locationId);
    formData.append('collector', collector);
    formData.append('notes', values.notes);
    formData.append('timestamp', values.timestamp);
    formData.append(`data`, JSON.stringify(data));
    if (!!files && files.length) {
        for (const file of files) {
            formData.append('pictures[]', file, file.name);
        }
    }
    formData.append('labels', JSON.stringify(labels));

    return instance.post('/samplingLogs/', formData, config);
}

const postHistorySamples = (token, locationId, collector, file, skipFlag) => {
    const config = setAuthHeader(token);

    const queryParams = new URLSearchParams();

    for (let el in locationId) {
        queryParams.append('locationId', locationId[el]);
    }
    queryParams.append('skipFlag', skipFlag);
    queryParams.append('collector', collector);

    const formData = new FormData();
    // formData.append('collector', collector);
    formData.append('file', file, file.name);

    return instance.post('/samplingLogs/history?' + queryParams.toString(), formData, config);
}


const postStagingSamples = (token, collector, locationId, values, labels, files) => {
    const config = setAuthHeader(token);
    const data = [];
    const dataKeys = Object.keys(values);
    for (let index = 0; index < dataKeys.length; index++) {
        const key = dataKeys[index];
        if (key === 'notes' || key === 'timestamp' || values[key] === "") continue;
        else data.push({ resourceTypeKey: key, value: values[key] });
    }

    const formData = new FormData();

    formData.append('samplingType', 3);
    formData.append('locationId', locationId);
    formData.append('collector', collector);
    formData.append('notes', values.notes);
    formData.append('timestamp', values.timestamp);
    formData.append('data', JSON.stringify(data));
    if (!!files && files.length) {
        for (const file of files) {
            formData.append('pictures[]', file, file.name);
        }
    }
    formData.append('labels', JSON.stringify(labels));

    return instance.post('/samplingLogs/staging', formData, config);

}

const postHistoryStagingSamples = (token, locationId, collector, file, skipFlag) => {
    const config = setAuthHeader(token);

    const queryParams = new URLSearchParams();

    for (let el in locationId) {
        queryParams.append('locationId', locationId[el]);
    }
    queryParams.append('skipFlag', skipFlag);
    queryParams.append('collector', collector);

    const formData = new FormData();
    // formData.append('collector', collector);
    formData.append('file', file, file.name);

    return instance.post('/samplingLogs/history/staging?' + queryParams.toString(), formData, config);
}



const postStagingSamplesReview = (token, collector, locationId, values, labels, files) => {
    const config = setAuthHeader(token);
    const data = [];
    const dataKeys = Object.keys(values);
    for (let index = 0; index < dataKeys.length; index++) {
        const key = dataKeys[index];
        if (key === 'notes' || key === 'timestamp' || values[key] === "") continue;
        else data.push({ resourceTypeKey: key, value: values[key] });
    }

    const formData = new FormData();

    formData.append('samplingType', 3);
    formData.append('locationId', locationId);
    formData.append('collector', collector);
    formData.append('notes', values.notes);
    formData.append('timestamp', values.timestamp);
    formData.append('data', JSON.stringify(data));
    if (!!files && files.length) {
        for (const file of files) {
            formData.append('pictures[]', file, file.name);
        }
    }
    formData.append('labels', JSON.stringify(labels));

    return instance.post('/samplingLogs/staging/review', formData, config);

}

const postHistoryStagingSamplesReview = (token, locationId, collector, file, skipFlag) => {
    const config = setAuthHeader(token);

    const queryParams = new URLSearchParams();

    for (let el in locationId) {
        queryParams.append('locationId', locationId[el]);
    }
    queryParams.append('skipFlag', skipFlag);
    queryParams.append('collector', collector);

    const formData = new FormData();
    // formData.append('collector', collector);
    formData.append('file', file, file.name);

    return instance.post('/samplingLogs/history/staging/review?' + queryParams.toString(), formData, config);
}

const editSample = (token, samplingLogId, locationId, measurementValues, timestamp, notes, labels,) => {
    const config = setAuthHeader(token);
    const formData = new FormData();
    formData.append('data', JSON.stringify(measurementValues));
    formData.append('locationId', locationId);
    formData.append('notes', notes);
    formData.append('labels', JSON.stringify(labels));
    formData.append('timestamp', timestamp);


    return instance.put('samplingLogs/edit/' + samplingLogId, formData, config);
}

const samplingLogsApi = {
    getSamplingNumbers,
    getQualityLevelDistribution,
    getSampleTypeDistribution,
    getSamplingValues,
    getSamplingDetails,
    getStagingSamples,
    getReviewedStagingSamples,
    getStagingSamplingDetails,
    approveStagingSample,
    reviewStagingSample,
    rejectStagingSample,
    postSamples,
    postHistorySamples,
    postStagingSamples,
    postHistoryStagingSamples,
    postStagingSamplesReview,
    postHistoryStagingSamplesReview,
    editSample,
}
export default samplingLogsApi;