import React, { useCallback, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { DisplayResourceName } from "components";
import { Box, FormControlLabel, TextField, RadioGroup, Radio, FormControl, FormLabel } from "@mui/material";

export default function EditSampleCustomFields(props) {
    const { formik, resource, existingSamplingLog } = props;
    const intl = useIntl();
    const foundMeasurement = existingSamplingLog.data.find(type => Number(type.resourceTypeKey) === resource.key)
    const [checked, setChecked] = useState(foundMeasurement ? foundMeasurement.value !== 'Bez' : '');
    const [description, setDescription] = useState(foundMeasurement?.value)

    const renderFields = useCallback(() => {
        return (
            <>
                {resource.dataType === 'Object' ? <Box key={resource.key} sx={{ py: 1.5 }}>
                    <FormControl>
                        <FormLabel><DisplayResourceName resource={resource} /></FormLabel>
                        <RadioGroup
                            row
                            name={resource.key.toString()}
                            value={checked}
                        >

                            <FormControlLabel value={true} control={<Radio onClick={(e) => {
                                if (e.target.value !== checked) {
                                    setChecked(e.target.value)
                                    formik.setFieldValue(resource.key.toString(), {
                                        value: e.target.value === 'true' ? true
                                            : e.target.value === 'false' ? false
                                                : "",
                                        description: e.target.value === 'true' ? formik.values[resource.key] ? (formik.values[resource.key].description || 'Bez') : 'Bez' : 'Bez'

                                    })
                                    if (e.target.value === 'false') setDescription('Bez');
                                }
                                else {
                                    setChecked('')
                                    formik.setFieldValue(resource.key.toString(), {
                                        value: '', description: ''
                                    })
                                }

                            }} />} label={<FormattedMessage id={"PRESENT"} />} />

                            <FormControlLabel value={false} control={<Radio onClick={(e) => {
                                if (e.target.value !== checked) {
                                    setChecked(e.target.value)
                                    formik.setFieldValue(resource.key.toString(), {
                                        value: e.target.value === 'true' ? true
                                            : e.target.value === 'false' ? false
                                                : "",
                                        description: e.target.value === 'true' ? formik.values[resource.key].description : 'Bez'
                                    })
                                    if (e.target.value === 'false') setDescription('Bez');


                                }
                                else {
                                    setChecked('')
                                    formik.setFieldValue(resource.key.toString(), {
                                        value: '', description: ''
                                    })
                                }
                            }} />} label={<FormattedMessage id={"NOT_PRESENT"} />} />
                        </RadioGroup>
                    </FormControl>

                    {(checked === 'true' || checked === true) && resource.dataType !== 'Boolean' ? <TextField
                        sx={{ p: 0, mt: -1 }}
                        type="text"
                        name={resource.key.toString()}
                        required
                        label={<span><FormattedMessage id={"DESCRIPTION"} /> </span>}
                        value={description}
                        onChange={(e) => { setDescription(e.target.value); formik.setFieldValue(resource.key.toString(), { value: true, description: e.target.value }) }}
                        placeholder={resource.range.min !== null && resource.range.max !== null ? intl.formatMessage({ id: 'DATA_PLACEHOLDER' }) + ` (${resource.range.min} - ${resource.range.max})` : intl.formatMessage({ id: 'DATA_PLACEHOLDER' })}
                        error={Boolean(formik.errors[resource.key.toString()])}
                        helperText={formik.errors[resource.key.toString()]}
                        margin="normal"
                        variant="standard"
                        size="small"
                        fullWidth
                    />
                        : null}

                </Box>
                    : resource.dataType === 'Boolean' ? <Box key={resource.key} sx={{ py: 1.5 }}>
                        <FormControl>
                            <FormLabel><DisplayResourceName resource={resource} /></FormLabel>
                            <RadioGroup
                                row
                                name={resource.key.toString()}
                                value={checked}
                            >

                                <FormControlLabel value={true} control={<Radio onClick={(e) => {
                                    if (e.target.value !== checked) {
                                        setChecked(e.target.value)
                                        formik.setFieldValue(resource.key.toString(), e.target.value === 'true' ? true
                                            : e.target.value === 'false' ? false
                                                : ""
                                        )
                                    }
                                    else {
                                        setChecked('')
                                        formik.setFieldValue(resource.key.toString(), '')
                                    }

                                }} />} label={<FormattedMessage id={"PRESENT"} />} />

                                <FormControlLabel value={false} control={<Radio onClick={(e) => {
                                    if (e.target.value !== checked) {
                                        setChecked(e.target.value)
                                        formik.setFieldValue(resource.key.toString(), e.target.value === 'true' ? true
                                            : e.target.value === 'false' ? false
                                                : ""
                                        )
                                    }
                                    else {
                                        setChecked('')
                                        formik.setFieldValue(resource.key.toString(), '')
                                    }
                                }} />} label={<FormattedMessage id={"NOT_PRESENT"} />} />
                            </RadioGroup>
                        </FormControl>
                    </Box>
                        : <TextField
                            sx={{ p: 0, mt: 0 }}
                            key={resource.key}
                            type="text"
                            name={resource.key.toString()}
                            label={<span><DisplayResourceName resource={resource} /> {resource.unit ? <span>[{resource.unit}]</span> : ""}</span>}
                            value={formik.values[resource.key.toString()] || ""}
                            onChange={formik.handleChange}
                            placeholder={resource.range.min !== null && resource.range.max !== null ? intl.formatMessage({ id: 'DATA_PLACEHOLDER' }) + ` (${resource.range.min} - ${resource.range.max})` : intl.formatMessage({ id: 'DATA_PLACEHOLDER' })}
                            error={Boolean(formik.errors[resource.key.toString()])}
                            helperText={formik.errors[resource.key.toString()]}
                            margin="normal"
                            variant="standard"
                            size="small"
                            fullWidth
                        />
                }
            </>
        )
    }, [intl, formik, checked, resource, description]);

    return renderFields();
}