import React from 'react';
import { Chart, registerables } from 'chart.js';
import { sampleTypePalette } from '../utils/chartsCollorPalette';
import { Bar } from 'react-chartjs-2';
import defaultOptions from 'charts/utils/defaultOptions';
import { deepMerge } from 'utils';
import { useSelector } from "react-redux";
import { selectFontSize, selectFontWeight } from 'redux/appSlice';
import { useTheme, useMediaQuery } from '@mui/material';
import { useIntl } from 'react-intl';

Chart.register(...registerables);

const StackedBarChart = (props) => {
    const theme = useTheme();
    const intl = useIntl();
    const fontSize = useSelector(selectFontSize);
    const fontWeight = useSelector(selectFontWeight);
    const xtraSmallScreen = useMediaQuery(theme => theme.breakpoints.down('sm'));
    // inject palette colors to each serie
    if (props.data && props.data.datasets) {
        props.data.datasets.forEach((dataset) => {
            const dsColor = sampleTypePalette[dataset.key];
            dataset.backgroundColor = dsColor;
        })
    }

    const options = {
        plugins: {
            tooltip: {
                callbacks: {
                    footer: (tooltipItems) => {
                        let sum = 0;
                        tooltipItems.forEach(tooltipItem => sum += tooltipItem.parsed.y);
                        return `${intl.formatMessage({ id: 'SUM' })}: ${sum}`;
                    }
                },
                titleFont: {
                    size: xtraSmallScreen ? 10 : fontSize
                },
                bodyFont: {
                    size: xtraSmallScreen ? 10 : fontSize
                },
                footerFont: {
                    size: xtraSmallScreen ? 10 : fontSize
                }
            },
            legend: {
                labels: {
                    color: theme.palette.text.primary,
                    font: {
                        size: xtraSmallScreen ? 10 : fontSize,
                        weight: fontWeight < 0 ? 'lighter' : fontWeight < 100 ? 'normal' : 'bolder'
                    }
                }
            }
        },
        scales: {
            x: {
                stacked: true,
                ticks: {
                    color: theme.palette.text.primary,
                    font: {
                        size: xtraSmallScreen ? 10 : fontSize,
                        weight: fontWeight < 0 ? 'lighter' : fontWeight<100 ? 'normal' : 'bolder'
                    }
                }
            },
            y: {
                stacked: true,
                ticks: {
                    color: theme.palette.text.primary,
                    font: {
                        size: xtraSmallScreen ? 10 : fontSize,
                        weight: fontWeight < 0 ? 'lighter' : fontWeight<100 ? 'normal' : 'bolder'
                    }
                }
            }
        }
    }

    return (
        <Bar className='chart' data={props.data} options={deepMerge(defaultOptions, options)} ref={props.chartRef} />
    );
}

export default StackedBarChart;