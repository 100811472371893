import React, { useCallback, useState, useEffect } from "react";
import { useSelector } from 'react-redux';
import { userSelector } from "redux/userSlice";
import { selectLanguage } from "redux/appSlice";
import { selectSeverityTypesConf } from "redux/configurationSlice";
import { selectDateRange } from "redux/dateRangeSlice";
import { FormattedMessage } from 'react-intl';
import { AlarmReportCard, LoadingData, AlertSnackbar } from "components";
import { Container, Typography } from '@mui/material';
import API from 'api';


function SingleAlarmView(props) {
    const { alarmId } = props;
    const [alarmsData, setAlarmsData] = useState(null);
    const severityTypes = useSelector(selectSeverityTypesConf);
    const selectedDateRange = useSelector(selectDateRange);
    const lang = useSelector(selectLanguage);
    const { token } = useSelector(userSelector);
    const [alert, setAlert] = useState({ open: false });

    const onAlertClose = () => setAlert({ ...alert, open: false });

    useEffect(() => {
        setAlarmsData(null);

        API.alarms.getSingleAlarm(token, alarmId)
            .then(item => {
                setAlarmsData({
                    _id: item.data._id,
                    name: item.data.name,
                    message: item.data.message,
                    triggeredTime: item.data.triggeredTime,
                    severity: severityTypes.find(el => el.key === item.data.severityType).value,
                    locationId: item.data.locationId,
                    acknowledgedBy: item.data.acknowledgedBy,
                    acknowledgedTime: item.data.acknowledgedTime,
                    clearedBy: item.data.clearedBy,
                    clearedTime: item.data.clearedTime,
                    conditionsPassed: item.data.conditionsPassed,
                    collector: item.data.collector
                });

            }).catch(error => {
                setAlert({ open: true, messageId: (error.data && error.data.id) || "APP.ERROR", severity: "error" });
                setAlarmsData({ error });
            })

    }, [token, alarmId, severityTypes, selectedDateRange, lang]);

    const render = useCallback(() => {
        const updateAlarmsData = (updatedAlarm) => {
            const alarmHistoryData = {
                ...alarmsData,
                acknowledgedBy: updatedAlarm.acknowledgedBy,
                acknowledgedTime: updatedAlarm.acknowledgedTime,
                clearedBy: updatedAlarm.clearedBy,
                clearedTime: updatedAlarm.clearedTime
            };
            setAlarmsData(alarmHistoryData)
        }

        if (alarmsData !== null) return <AlarmReportCard alarm={alarmsData} updateAlarmsData={updateAlarmsData} setUpdatedAlarm={setAlarmsData} />
        else if (alarmsData === null) return <LoadingData />;
        else if (alarmsData.error) return <Typography variant='body2'><FormattedMessage id="NO_DATA" /></Typography>;
        else return <Typography variant='body2'><FormattedMessage id="NO_DATA_TIME_PERIOD" /></Typography>;
    }, [alarmsData]);

    return <Container>
        <AlertSnackbar open={alert.open} onClose={onAlertClose} severity={alert.severity} messageId={alert.messageId} />
        {render()}

    </Container>;
}

export default SingleAlarmView;