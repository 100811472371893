import './footerIRI.scss';
import { useIntl } from 'react-intl';

function FooterIRI() {
    const intl = useIntl();

    return (
        <div className='footer-main'>
            <div className='footer-fund-collection'>
                <img className='footer-EU-logo' src={window.location.origin + '/images/IRI/Footer/EU-footer-IRI.png'} alt='EU-logo' />
                <img className='footer-MRRF-logo' src={window.location.origin + '/images/IRI/Footer/MRRF-footer-IRI.png'} alt='MRRF-logo' />
                <img className='footer-ESIF-logo' src={window.location.origin + '/images/IRI/Footer/ESIF-footer-IRI.png'} alt='ESIF-logo' />
                <img className='footer-OPPK-logo' src={window.location.origin + '/images/IRI/Footer/OPKK-footer-IRI.png'} alt='OPPK-logo' />
            </div>
            <div className='footer-content'>
                <div className='footer-contact-personel'>
                    <div className='footer-project-manager'>
                        <div className='project-manager-title'>
                            {intl.formatMessage({ id: 'FOOTER_IRI.PROJECT_LEAD' })}
                        </div>
                        <div className='project-manager-name'>
                            {intl.formatMessage({ id: 'FOOTER_IRI.PROJECT_LEAD_NAME' })}
                        </div>
                        <div className='project-manager-mail'>
                            <span className='project-manager-mail-mark'>{intl.formatMessage({ id: 'FOOTER_IRI.MAIL' })}</span>
                            {intl.formatMessage({ id: 'FOOTER_IRI.PROJECT_LEAD_MAIL' })}
                        </div>
                        <div className='project-manager-phone'>
                            <span className='project-manager-phone-mark'>{intl.formatMessage({ id: 'FOOTER_IRI.PHONE' })}</span>
                            {intl.formatMessage({ id: 'FOOTER_IRI.PROJECT_LEAD_PHONE' })}
                        </div>
                    </div>
                    <div className='footer-tehnical-project-manager'>
                        <div className='tehnical-project-manager-title'>
                            {intl.formatMessage({ id: 'FOOTER_IRI.TEHNICAL_PROJECT_MANAGER' })}
                        </div>
                        <div className='tehnical-project-manager-name'>
                            {intl.formatMessage({ id: 'FOOTER_IRI.TEHNICAL_PROJECT_MANAGER_NAME' })}
                        </div>
                        <div className='tehnical-project-manager-mail'>
                            <span className='project-manager-mail-mark'>{intl.formatMessage({ id: 'FOOTER_IRI.MAIL' })}</span>
                            {intl.formatMessage({ id: 'FOOTER_IRI.TEHNICAL_PROJECT_MANAGER_MAIL' })}
                        </div>
                        <div className='tehnical-project-manager-phone'>
                            <span className='project-manager-phone-mark'>{intl.formatMessage({ id: 'FOOTER_IRI.PHONE' })}</span>
                            {intl.formatMessage({ id: 'FOOTER_IRI.TEHNICAL_PROJECT_MANAGER_PHONE' })}
                        </div>
                    </div>
                    <div className='footer-commercial-issues'>
                        <div className='commercial-issues-title'>
                            {intl.formatMessage({ id: 'FOOTER_IRI.COMMERCIAL_ISSUES' })}
                        </div>
                        <div className='commercial-issues-name'>
                            {intl.formatMessage({ id: 'FOOTER_IRI.COMMERCIAL_ISSUES_NAME' })}
                        </div>
                        <div className='commercial-issues-mail'>
                            <span className='project-manager-mail-mark'>{intl.formatMessage({ id: 'FOOTER_IRI.MAIL' })}</span>
                            {intl.formatMessage({ id: 'FOOTER_IRI.COMMERCIAL_ISSUES_MAIL' })}
                        </div>
                        <div className='commercial-issues-phone'>
                            <span className='project-manager-phone-mark'>{intl.formatMessage({ id: 'FOOTER_IRI.PHONE' })}</span>
                            {intl.formatMessage({ id: 'FOOTER_IRI.COMMERCIAL_ISSUES_PHONE' })}
                        </div>
                    </div>
                </div>
            </div>
            <div className='footer-copyright'>
                <span>{intl.formatMessage({ id: 'FOOTER_IRI.COPYRIGHT' })}</span>
            </div>
        </div>
    )
}

export default FooterIRI;
