import { DefaultLayout, PublicLayout, EmptyState } from 'layouts';
import { MapComponent } from 'components';
import { useSelector } from 'react-redux';
import { userSelector } from 'redux/userSlice';



function MapPage() {
    return <EmptyState component={<MapComponent />} />;
}

export default function MapPageComponent(props) {
    const { isSuccess: loggedIn } = useSelector(userSelector);

    if (loggedIn) return <DefaultLayout page={<MapPage />} />;
    else return <PublicLayout page={<MapPage />} />;
};
