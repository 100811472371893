import React, { useCallback, useState, useEffect } from "react";
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from "react-redux";
import { userSelector } from "redux/userSlice";
import { selectSeverityTypesConf } from "redux/configurationSlice";
import { selectDateRange } from "redux/dateRangeSlice";
import { AlarmHistoryTable, LoadingData, AlertSnackbar } from "components";
import { Alert, Card, CardContent, CardHeader } from '@mui/material';
import API from 'api';


function AlarmsTableContainer(props) {
    const { locations, severities, selectAcknowledged, selectUnacknowledged, selectCleared, selectActive } = props;
    const intl = useIntl();
    const [alarmsData, setAlarmsData] = useState(null);
    const severityTypes = useSelector(selectSeverityTypesConf);
    const selectedDateRange = useSelector(selectDateRange);
    const { token } = useSelector(userSelector);
    const [alert, setAlert] = useState({ open: false });

    const onAlertClose = () => setAlert({ ...alert, open: false });


    useEffect(() => {

        API.alarms.getAllAlarms(token, selectedDateRange.dateFrom, selectedDateRange.dateTo, locations, severities, selectAcknowledged, selectUnacknowledged, selectCleared, selectActive)
            .then(items => {
                if (items.data) {
                    const data = items.data.map(collection => {
                        return {
                            id: collection._id,
                            _id: collection._id,
                            name: collection.name,
                            message: collection.message,
                            triggeredTime: collection.triggeredTime,
                            severity: severityTypes.find(el => el.key === collection.severityType).value,
                            locationId: collection.locationId,
                            acknowledgedBy: collection.acknowledgedBy,
                            acknowledgedTime: collection.acknowledgedTime,
                            clearedBy: collection.clearedBy,
                            clearedTime: collection.clearedTime,
                            conditionsPassed: collection.conditionsPassed,
                            collector: collection.collector
                        }
                    });
                    setAlarmsData(data);
                }
                else setAlarmsData([]);
            }).catch(error => {
                setAlert({ open: true, messageId: (error.data && error.data.id) || "APP.ERROR", severity: "error" });
                setAlarmsData({ error });
            });

    }, [token, severityTypes, selectAcknowledged, selectUnacknowledged, selectCleared, selectActive, locations, severities, selectedDateRange, intl]);

    const render = useCallback(() => {
        const updateAlarmsData = (updatedAlarm) => {
            const alarmHistoryData = [...alarmsData]
            const index = alarmHistoryData.findIndex(el => el._id === updatedAlarm._id);

            alarmHistoryData[index] = {
                ...alarmsData[index],
                acknowledgedBy: updatedAlarm.acknowledgedBy,
                acknowledgedTime: updatedAlarm.acknowledgedTime,
                clearedBy: updatedAlarm.clearedBy,
                clearedTime: updatedAlarm.clearedTime
            };

            setAlarmsData(alarmHistoryData);
        }

        if (alarmsData !== null && alarmsData.length) return <AlarmHistoryTable alarmsData={alarmsData} updateAlarmsData={updateAlarmsData} />
        else if (alarmsData === null) return <LoadingData />;
        else if (alarmsData.error) return <Alert severity="error"><FormattedMessage id='NO_DATA' /></Alert>;
        else return <Alert severity="warning"><FormattedMessage id='NO_DATA_TIME_PERIOD' /></Alert>;
    }, [alarmsData]);



    return <>
        <AlertSnackbar open={alert.open} onClose={onAlertClose} severity={alert.severity} messageId={alert.messageId} />
        <Card>
            <CardHeader title={<FormattedMessage id="ALARM.DATA_TABLE" />} />
            <CardContent>
                {render()}
            </CardContent>
        </Card>
    </>;
}

export default AlarmsTableContainer;