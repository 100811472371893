import React, { useEffect, useState, useCallback } from 'react';
import { Alert, Card, CardContent, CardHeader } from '@mui/material';
import { useIntl, FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { userSelector } from 'redux/userSlice';
import { selectDateRange } from 'redux/dateRangeSlice';
import { formatDateLocale } from 'utils';
import { StackedBarChart } from 'charts'
import { selectSamplingTypesConf } from "redux/configurationSlice";
import { AggregationTabs, LoadingData, AlertSnackbar } from 'components';

import API from 'api';

const SamplingLogsSamplesCount = (props) => {
  const { locations, sampleTypes, qualityTypes, collectors, pictureSample } = props;
  const { token } = useSelector(userSelector);
  const selectedDateRange = useSelector(selectDateRange);
  const samplingTypesConfig = useSelector(selectSamplingTypesConf);
  const intl = useIntl();
  const [data, setData] = useState(null);
  const [agr, setAgr] = useState({ group: 'NO_GROUP', time: null });
  const [alert, setAlert] = useState({ open: false });

  const onAlertClose = () => setAlert({ ...alert, open: false });

  const convertData = useCallback((apiData) => {
    const samplingTypes = samplingTypesConfig.map(type => 'TYPE_' + type.value);
    const chartData = {
      labels: [],
      datasets: samplingTypes.map(type => (
        {
          key: type,
          label: intl.formatMessage({ id: 'SAMPLE_LOG.' + type }),
          data: []
        }
      ))
    }
    apiData.forEach(row => {
      // store date as chart label
      chartData.labels.push(formatDateLocale(row.date, { getShortFormat: true, aggregationMode: agr.time }));
      samplingTypes.forEach((type, index) => chartData.datasets[index].data.push(row.values[type]));
    })

    setData({ datasets: chartData.datasets, labels: chartData.labels });
  }, [setData, intl, samplingTypesConfig, agr.time]);

  useEffect(() => {
    API.samplingLogs.getSamplingNumbers(token, locations, selectedDateRange.dateFrom, selectedDateRange.dateTo, qualityTypes, sampleTypes, agr.time, collectors, pictureSample)
      .then(items => {
        if (items.data && items.data.length) {
          convertData(items.data);
        }
        else setData({});
      }).catch(error => {
        setAlert({ open: true, messageId: (error.data && error.data.id) || "APP.ERROR", severity: "error" });
        setData({ error: error });
      });
  }, [token, locations, selectedDateRange, sampleTypes, qualityTypes, agr, collectors, pictureSample, convertData]);

  const render = useCallback(() => {
    if (data !== null && data.datasets) return <StackedBarChart name={"SampleCountBarChart "} data={data} chartRef={props.chartRef} />;
    else if (data === null) return <LoadingData noText />;
    else if (data.error) return <Alert severity="error"><FormattedMessage id='NO_DATA' /></Alert>;
    else return <Alert severity="warning"><FormattedMessage id='NO_DATA_TIME_PERIOD' /></Alert>
  }, [data, props.chartRef]);

  const handleAggregationChange = (time) => {
    if (time === "h") setAgr({ group: 'PER_HOUR', time: 'h' });
    else if (time === "d") setAgr({ group: 'PER_DAY', time: 'd' });
    else if (time === "w") setAgr({ group: 'PER_WEEK', time: 'w' });
    else if (time === "m") setAgr({ group: 'PER_MONTH', time: 'm' });
    else setAgr({ group: 'NO_GROUP', time: null });;
  }


  return <>
    <AlertSnackbar open={alert.open} onClose={onAlertClose} severity={alert.severity} messageId={alert.messageId} />
    <Card sx={{ width: '100%' }}>
      <CardHeader title={<FormattedMessage id="SAMPLES" />}
        subheader={<FormattedMessage id={"GROUPING." + agr.group} />}
        action={
          <AggregationTabs
            disabled={data === null || data.error || !data.datasets}
            values={["d", "w", "m"]}
            setAggregation={handleAggregationChange}
          />
        }
      />
      <CardContent>
        {render()}
      </CardContent>
    </Card>
  </>
}

export default SamplingLogsSamplesCount;