import React, { useEffect, useState, useCallback, useRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { userSelector } from 'redux/userSlice';
import { selectDateRange } from 'redux/dateRangeSlice';
import { selectResourcesTypesConf } from 'redux/configurationSlice';
import { useNavigate } from "react-router-dom";
import { MultiAxisLineChart, ScatterChart } from 'charts'
import { AggregationTabs, LoadingData, AlertSnackbar } from 'components';

import { Menu, MenuItem, Alert, Box, Card, CardContent, CardHeader, CardActions, IconButton, Button, Stack, Autocomplete, Collapse, Grow, TextField, Typography, Tooltip, Switch, FormControlLabel, Divider, useMediaQuery } from '@mui/material';
import { OpenInNewOutlined, FilterAltOutlined, FilterAltOffOutlined, RestartAltOutlined } from '@mui/icons-material';
import { FileDownloadOutlined, KeyboardArrowDownOutlined, KeyboardArrowUpOutlined } from '@mui/icons-material';

import API from 'api';
import { exportData } from 'utils';

function MeasurementsTrendGraph(props) {
  const { title, attribution, locationId, selectable, resourceTypes, sampleTypes, aggregation, cardLink, selectedMeasurePointTypes, fullRowView, type } = props;
  const { token } = useSelector(userSelector);
  const intl = useIntl();
  const smallScreen = useMediaQuery(theme => theme.breakpoints.down("sm"));
  const navigate = useNavigate();
  const selectedDateRange = useSelector(selectDateRange);
  const resourceTypesConf = useSelector(selectResourcesTypesConf);
  const [resTypes, setResTypes] = useState([]);
  const [resTypeSelectExpanded, setResTypeSelectExpanded] = useState(false);
  const [data, setData] = useState(null);
  const [agr, setAgr] = useState({ group: 'NO_GROUP', time: null });
  const [changed, setChanged] = useState(false);
  const [alert, setAlert] = useState({ open: false });
  const chartRef = useRef(null);
  const [reset, setReset] = useState(0);
  const [decimation, setDecimation] = useState(true);
  const [loading, setLoading] = useState(false);

  const onAlertClose = () => setAlert({ ...alert, open: false });

  const selectFiltered = useCallback(() => {
    setResTypes(
      resourceTypesConf
        .filter(el => resourceTypes.includes(el.key) && selectable.includes(el.key))
        .concat(resourceTypesConf.filter(el => resourceTypes.includes(el.key) && !selectable.includes(el.key)))
        .slice(0, 10)
    );
    setChanged(false);
  }, [resourceTypesConf, resourceTypes, selectable, setResTypes, setChanged]);

  useEffect(() => {
    if (selectable) selectFiltered();
    else setResTypes(resourceTypesConf.filter(el => resourceTypes.includes(el.key)));
  }, [selectable, resourceTypes, resourceTypesConf, selectFiltered])

  useEffect(() => {
    if (aggregation) setAgr(aggregation);
    else return;
  }, [aggregation, setAgr]);

  const handleAggregationChange = (time) => {
    if (aggregation) return;
    else if (time === "h") setAgr({ group: 'PER_HOUR', time: 'h' });
    else if (time === "d") setAgr({ group: 'PER_DAY', time: 'd' });
    else if (time === "w") setAgr({ group: 'PER_WEEK', time: 'w' });
    else if (time === "m") setAgr({ group: 'PER_MONTH', time: 'm' });
    else setAgr({ group: 'NO_GROUP', time: null });
  }

  const convertData = useCallback((apiData) => {
    if (resourceTypesConf) {
      apiData.forEach(dataSerie => {
        const resource = resourceTypesConf.find(type => type.key === dataSerie._id.label)
        const labelId = resource.name;
        dataSerie.key = dataSerie._id.label;
        dataSerie.label = `${intl.formatMessage({ id: labelId })} [${resource.unit}]`;
      });
    }
    setData({ key: apiData.length, datasets: apiData });
  }, [setData, intl, resourceTypesConf]);

  useEffect(() => {
    if (resTypes.length) {
      setLoading(true);
      API.measurements.getMultiTypeMeasurements(token, locationId, resTypes.map(el => el.key), selectedDateRange.dateFrom, selectedDateRange.dateTo, sampleTypes, agr.time, selectedMeasurePointTypes, decimation).then(items => {
        if (items.data && items.data.length) {
          // store data in state
          convertData(items.data);
        }
        else setData({});
        setLoading(false);
      }).catch(error => {
        setData({ error: error });
        setAlert({ open: true, messageId: (error.data && error.data.id) || "APP.ERROR", severity: "error" });
        console.error("Error fetching data for MeasurementsTrendGraph, reason: ", error);
        setLoading(false);
      });
    }
    else setData({})
  }, [token, locationId, resTypes, selectedDateRange, sampleTypes, agr, convertData, selectedMeasurePointTypes, reset, decimation]);

  const renderChart = useCallback(() => {
    if (loading || data === null) return (
      <LoadingData noText size={2} />
    );
    else if (data !== null && data.datasets && resTypes.length !== 0) {
      if (type === 'manual') return <ScatterChart name={"trendLineChart_" + locationId} data={data} reset={reset} setReset={setReset} aggregation={agr.time} locationId={locationId} chartRef={chartRef} />
      else return <MultiAxisLineChart name={"trendLineChart_" + locationId} data={data} reset={reset} setReset={setReset} aggregation={agr.time} locationId={locationId} chartRef={chartRef} decimation={decimation} key={decimation} />
    }
    else if (data.error) return (
      <Alert severity="error"><FormattedMessage id='NO_DATA' /></Alert>
    );
    else if (resTypes.length === 0) return (
      <Alert severity="error"><FormattedMessage id='NO_PARAMETERS_SELECTED' /></Alert>
    );
    else return (
      <Alert severity="warning"><FormattedMessage id='NO_DATA_TIME_PERIOD' /></Alert>
    );
  }, [loading, data, agr, resTypes, locationId, type, reset, decimation])

  const searchOptions = resourceTypesConf.filter(el => resourceTypes.includes(el.key));
  const displayThreshold = 20; // Number of resource types needed for selection in order for helper text to appear
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleExportChange = (option, print) => {
    const base64Image = chartRef.current.toBase64Image();
    const ratio = fullRowView ? chartRef.current.$context.chart._aspectRatio : null
    exportData({ [option]: true }, title, intl.formatMessage({ id: 'MEASUREMENTS' }), null, null, base64Image, print, ratio);
    handleClose();
  }

  const DecimactionSwitch = <FormControlLabel
    key="decimation"
    control={<Switch size='small' checked={decimation} onChange={() => setDecimation(decimation => !decimation)} />}
    label={<Typography variant="subtitle2" color={"text." + (decimation ? "primary" : "secondary")}><FormattedMessage id="DECIMATION" /></Typography>}
  />;

  return <>
    <AlertSnackbar open={alert.open} onClose={onAlertClose} severity={alert.severity} messageId={alert.messageId} />
    <Card sx={{ width: '100%' }}>
      <CardHeader
        title={
          <Stack direction="row">
            {title}
          </Stack>
        }
        subheader={<FormattedMessage id={"GROUPING." + agr.group} />}
        action={
          !cardLink ?
            <Stack direction={smallScreen ? "column" : "row-reverse"}>
              <AggregationTabs
                disabled={aggregation || data === null || data.error || !data.datasets}
                values={["h", "d", "w"]}
                setAggregation={handleAggregationChange}
              />
              {agr?.group === 'NO_GROUP' && type !== 'manual' && !smallScreen && <Divider flexItem orientation='vertical' sx={{ mr: 1 }} />}
              <Collapse in={agr?.group === 'NO_GROUP' && type !== 'manual'} orientation={smallScreen ? "vertical" : "horizontal"} sx={{ pt: 1 }}>
                {DecimactionSwitch}
              </Collapse>
            </Stack>
            : (data !== null && data.datasets && resTypes.length !== 0) ? [
              DecimactionSwitch,
              <Button
                id="export-button"
                key="export-button"
                size='small'
                aria-controls={open ? 'export-data-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                variant="contained"
                disableElevation
                onClick={handleClick}
                startIcon={<FileDownloadOutlined />}
                endIcon={open ? <KeyboardArrowUpOutlined /> : <KeyboardArrowDownOutlined />}
              >
                {intl.formatMessage({ id: 'EXPORT' })}
              </Button>,
              <Menu
                id="export-data-menu"
                key='export-data-menu'
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
              >
                <MenuItem onClick={(e) => handleExportChange('getPdfData')} ><FormattedMessage id="EXPORT_PDF" /></MenuItem>
                <MenuItem onClick={(e) => handleExportChange('getPdfData', true)} > <FormattedMessage id="PRINT_PDF" /></MenuItem>
              </Menu>,
              <Tooltip title={<FormattedMessage id={"LOCATION.OPEN"} />} key="details" position="left" arrow>
                <IconButton name="info" onClick={() => navigate('/locations/details/' + locationId)}>
                  <OpenInNewOutlined fontSize="small" />
                </IconButton>
              </Tooltip>
            ] : <Tooltip title={<FormattedMessage id={"LOCATION.OPEN"} />} key="details" position="left" arrow>
              <IconButton name="info" onClick={() => navigate('/locations/details/' + locationId)}>
                <OpenInNewOutlined fontSize="small" />
              </IconButton>
            </Tooltip>
        }
      />
      <CardContent sx={{ cursor: 'pointer' }}>
        {renderChart()}
      </CardContent>

      {selectable && searchOptions.length > resTypes.length ? <CardActions>
        <Button onClick={() => setResTypeSelectExpanded(!resTypeSelectExpanded)} variant={resTypeSelectExpanded ? "contained" : "outlined"} startIcon={resTypes.length ? <FilterAltOutlined /> : <FilterAltOffOutlined />} size="small" color="primary" sx={{ ml: 1 }}>
          <FormattedMessage id="ADDITIONAL_RESOURCE_TYPES" />
        </Button>
        <Grow in={changed}>
          <Tooltip title={<FormattedMessage id="RESET" />} placement="right" >
            <IconButton color="primary" onClick={selectFiltered}>
              <RestartAltOutlined fontSize="small" />
            </IconButton>
          </Tooltip>
        </Grow>
      </CardActions> : null}
      <Collapse in={resTypeSelectExpanded}>
        <Autocomplete
          sx={{ mx: 2, mb: 1, pr: 4 }}
          multiple
          size="small"
          limitTags={10}
          disableCloseOnSelect
          id="multiple-resource-select"
          options={searchOptions}
          getOptionLabel={(el) => intl.formatMessage({ id: el.name })}
          isOptionEqualToValue={(opt, val) => opt.key === val.key}
          value={resTypes}
          onChange={(e, value) => { setResTypes(value); setChanged(true) }}
          filterSelectedOptions
          renderInput={(params) => (
            <TextField {...params}
              color={resTypes.length > displayThreshold ? "warning" : "primary"}
              helperText={resTypes.length > displayThreshold && <Typography variant="caption" color="warning.main"><FormattedMessage id="SURPLUS_RESOURCES" /></Typography>}
              placeholder={`${resTypes.length ? intl.formatMessage({ id: "AND_LOWERCASE" }) : ""} ${searchOptions.length - resTypes.length} ${intl.formatMessage({ id: 'HIDDEN_LOWERCASE' })}`}
            />
          )}
          fullWidth
        />
      </Collapse>

      {attribution && <Box sx={{ px: 2, pb: 1 }}>
        {attribution}
      </Box>}
    </Card>
  </>

}

export default React.memo(MeasurementsTrendGraph);