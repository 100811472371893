import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import { NewNoteDialog, AlertSnackbar } from 'components';
import { userSelector, permissionSelector } from 'redux/userSlice';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Button, Stack, Typography, Card, Tooltip, IconButton } from '@mui/material';
import { OpenInNewOutlined } from '@mui/icons-material';
import API from 'api';

function NoteDetails(props) {
    const { data } = props;
    const [open, setOpen] = useState(false);
    const user = useSelector(userSelector);
    const noteCreatorRights = useSelector((state) => permissionSelector(state, 'create-notes'));
    const navigate = useNavigate();
    const [alert, setAlert] = useState({ open: false });

    const onAlertClose = () => setAlert({ ...alert, open: false });

    const resendNote = () => API.notes.resendNote(user.token, data.id).catch(error => {
        console.error("An error has occured: ", error);
        setAlert({ open: true, messageId: (error.data && error.data.id) || "APP.ERROR", severity: "error" });
    });

    const CustomText = (props) => <Stack direction="column">
        <Typography color='primary.main' variant='body2'><FormattedMessage id={props.messageId} /></Typography>
        {props.component ? props.component : <Typography variant='body1'>{props.text}</Typography>}
    </Stack>;

    return <>
        <AlertSnackbar open={alert.open} onClose={onAlertClose} severity={alert.severity} messageId={alert.messageId} />
        <Stack direction="column" spacing={2}>
            <Stack spacing={1} direction={'column'} sx={{ px: 1 }}>
                <CustomText messageId="USERNAME" text={data.username} />
                <CustomText messageId="LOCATION" text={data.location} />
                <CustomText messageId="NOTIFICATION_TYPE" text={data.notificationTypesLocale.join(", ")} />
                <CustomText messageId="NOTE_TYPE" text={data.noteTypeLocale} />
                <CustomText messageId="NOTE_TITLE" text={data.title} />
                <CustomText messageId="NOTE_TEXT" component={
                    <Card variant='outlined' sx={{ p: 0.5, fontStyle: 'italic' }}>
                        <Typography variant='body1'>{data.text}</Typography>
                    </Card>
                } />
            </Stack>
            <Stack direction="row">
                <Button variant="text" disabled={!(noteCreatorRights && noteCreatorRights.includes(data.locationId))} onClick={() => setOpen(true)}><FormattedMessage id="COPY" /></Button>
                <Button variant="text" disabled={!(noteCreatorRights && noteCreatorRights.includes(data.locationId) && data.sendFailed === [])} onClick={resendNote}><FormattedMessage id="RESEND" /></Button>
                <div style={{ marginLeft: 'auto' }}>
                    <Tooltip title={<FormattedMessage id='VIEW_LOCATION' />} arrow>
                        <IconButton onClick={() => navigate('/locations/details/' + data.locationId)} ><OpenInNewOutlined fontSize="small" /></IconButton>
                    </Tooltip>
                </div>
            </Stack>
            <NewNoteDialog open={open} handleDialogClose={() => { setOpen(false) }} noteType={data.noteType} notificationTypes={data.notificationTypes} noteTitle={data.title} noteText={data.text} groupLevel={data.groupLevel} locationId={data.locationId} />
        </Stack>
    </>
}

export default NoteDetails;