import React, { useState } from 'react';
import { FooterIRI, AlertSnackbar } from 'components';
import { FormattedMessage, useIntl } from 'react-intl';
import API from 'api';

import './contactsIRI.scss';

function ContactsIRI() {
    const intl = useIntl();
    const navigatorLanguage = navigator.language.split(/[-_]/)[0];
    const [language] = useState(navigatorLanguage);
    const [isValid, setIsValid] = useState(null);
    const [data, setData] = useState({
        name: null,
        phone: null,
        email: null,
        message: null,
        locale: language
    })
    const [alert, setAlert] = useState({ open: false });

    const onAlertClose = () => setAlert({ ...alert, open: false });

    const handleInputChange = (event) => {
        const { value, name } = event.target;
        setData({ ...data, [name]: value })
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        if (isEmailAddress(data.email)) {
            setIsValid(true);
            API.mail.contactSupport(data).catch(error => {
                console.error("An error has occured: ", error);
                setAlert({ open: true, messageId: (error.data && error.data.id) || "APP.ERROR", severity: "error" });
            })
            event.target.reset();
        }
    }

    const isEmailAddress = (email) => {
        //eslint-disable-next-line
        var pattern = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;

        if (!pattern.test(email)) {
            setIsValid(false);
            return false;
        }
        return true;

    };

    return <>
        <AlertSnackbar open={alert.open} onClose={onAlertClose} severity={alert.severity} messageId={alert.messageId} />
        <div className='contacts-page-body'>
            <div className='contacts-image-container'>
                <img className='contacts-shutterstock-image' src={window.innerWidth > 960 ? window.location.origin + '/images/IRI/Contacts/shutterstock-image.png' : (window.innerHeight > window.innerWidth ? window.location.origin + '/images/IRI/Contacts/shutterstock-image-mobile.png' : window.location.origin + '/images/IRI/Contacts/shutterstock-image.png')} alt='shutterstock' />
                <hr className='contacts-separator-line-image' />
                <div className='contacts-image-caption'>{intl.formatMessage({ id: 'LANDING_PAGE_IRI.MENU_CONTACTS' }).toUpperCase()}</div>
            </div>
            <div className='contacts-page-container'>
                <div className='contacts-title'>{intl.formatMessage({ id: 'FOOTER_IRI.PROJECT_LEAD' })}
                    <div className='contacts-subtitle-one'>{intl.formatMessage({ id: 'FOOTER_IRI.PROJECT_LEAD_NAME' })} </div>
                    <div className='contacts-subtitle-two'>
                        <span className='contacts-mail-mark'>{intl.formatMessage({ id: 'FOOTER_IRI.MAIL' })}</span>
                        {intl.formatMessage({ id: 'FOOTER_IRI.PROJECT_LEAD_MAIL' })}
                    </div>
                    <div className='contacts-subtitle-three'>
                        <span className='contacts-phone-mark'>{intl.formatMessage({ id: 'FOOTER_IRI.PHONE' })}</span>
                        {intl.formatMessage({ id: 'FOOTER_IRI.PROJECT_LEAD_PHONE' })}
                    </div>
                </div>
                <div className='contacts-title'>{intl.formatMessage({ id: 'FOOTER_IRI.TEHNICAL_PROJECT_MANAGER' })}
                    <div className='contacts-subtitle-one'>{intl.formatMessage({ id: 'FOOTER_IRI.TEHNICAL_PROJECT_MANAGER_NAME' })} </div>
                    <div className='contacts-subtitle-two'>
                        <span className='contacts-mail-mark'>{intl.formatMessage({ id: 'FOOTER_IRI.MAIL' })}</span>
                        {intl.formatMessage({ id: 'FOOTER_IRI.TEHNICAL_PROJECT_MANAGER_MAIL' })}
                    </div>
                    <div className='contacts-subtitle-three'>
                        <span className='contacts-phone-mark'>{intl.formatMessage({ id: 'FOOTER_IRI.PHONE' })}</span>
                        {intl.formatMessage({ id: 'FOOTER_IRI.TEHNICAL_PROJECT_MANAGER_PHONE' })}
                    </div>
                </div>
                <div className='contacts-title'>{intl.formatMessage({ id: 'FOOTER_IRI.COMMERCIAL_ISSUES' })}
                    <div className='contacts-subtitle-one'>{intl.formatMessage({ id: 'FOOTER_IRI.COMMERCIAL_ISSUES_NAME' })} </div>
                    <div className='contacts-subtitle-two'>
                        <span className='contacts-mail-mark'>{intl.formatMessage({ id: 'FOOTER_IRI.MAIL' })}</span>
                        {intl.formatMessage({ id: 'FOOTER_IRI.COMMERCIAL_ISSUES_MAIL' })}
                    </div>
                    <div className='contacts-subtitle-three'>
                        <span className='contacts-phone-mark'>{intl.formatMessage({ id: 'FOOTER_IRI.PHONE' })}</span>
                        {intl.formatMessage({ id: 'FOOTER_IRI.COMMERCIAL_ISSUES_PHONE' })}
                    </div>
                </div>
            </div>
            <div className='contact-report-form-container'>
                <form className='contact-report-form' onSubmit={handleSubmit}>
                    <div className='contact-report-form-header'>
                        <div className='contact-report-form-contact'>{intl.formatMessage({ id: 'CONTACTS_PAGE_IRI.REPORT_CONTACT' }).toUpperCase()}</div>
                        <img className='contact-report-form-logo' src={window.location.origin + '/images/IRI/Contacts/waterq-logo.png'} alt='waterQ-logo' />
                    </div>
                    <div className='contact-report-form-body'>
                        <input name='name' className='contact-report-form-input' placeholder={intl.formatMessage({ id: 'NAME' })} onChange={(e) => { handleInputChange(e) }} required ></input>
                        <input name='email' className='contact-report-form-input' placeholder={intl.formatMessage({ id: 'EMAIL' })} onChange={(e) => { handleInputChange(e) }} required ></input>
                        {isValid === false ? <FormattedMessage className="warning-message" id="INVALID_EMAIL" /> : null}
                        <input name='phone' className='contact-report-form-input' placeholder={intl.formatMessage({ id: 'PHONE' })} onChange={(e) => { handleInputChange(e) }}></input>
                        <input name='message' className='contact-report-form-message' placeholder={intl.formatMessage({ id: 'MESSAGE' })} onChange={(e) => { handleInputChange(e) }} required ></input>
                    </div>
                    <div className='contact-report-form-footer'>
                        <button type="submit" className='contact-report-form-button'><span>{intl.formatMessage({ id: 'SEND' }).toUpperCase()}</span></button>
                        {isValid === true ? <div className='contact-report-form-submitted'><FormattedMessage className="done-message" id="SUBMITTED" /> </div> : null}
                    </div>
                </form>
            </div>
            <FooterIRI />
        </div>
    </>
}

export default ContactsIRI;