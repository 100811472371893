import React from "react";
import { useSelector } from "react-redux";
import { userSelector } from "redux/userSlice";
import { permissionSelector } from "redux/userSlice";
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { AlarmProfileDetails, AlarmProfileForm, AlarmProfileButtons } from "components";
import { Button, ButtonGroup, Chip, Card, CardContent, CardHeader, Tooltip, IconButton } from '@mui/material';
import { EditOutlined, NotificationsActiveOutlined, NotificationsPausedOutlined } from '@mui/icons-material';

const RenderActions = (props) => {
    const user = useSelector(userSelector);
    const updateRights = useSelector((state) => permissionSelector(state, 'update-alarmProfiles'));
    return <AlarmProfileButtons
        detailsPage
        key="actions"
        alarmProfileId={props.alarm._id}
        locationId={props.alarm.locationId}
        enabled={props.alarm.enabled}
        user={user}
        alarm={props.alarm}
        updateAlarmsData={props.updateAlarmsData}>
        {!props.editFlag && <Tooltip key="edit" title={<FormattedMessage id="EDIT" />} arrow>
            <span>
                <IconButton disabled={!updateRights} color="primary" onClick={() => props.navigate('?edit')}>
                    <EditOutlined name="info" fontSize="small" />
                </IconButton>
            </span>
        </Tooltip>}
    </AlarmProfileButtons>
}

export default function AlarmProfileCard(props) {
    const { alarm, mini, editFlag, updateAlarmsData } = props;
    const navigate = useNavigate();

    return mini ? <>
        <AlarmProfileDetails alarm={alarm} />
        <ButtonGroup>
            <Button variant="text" onClick={() => navigate(alarm._id)}><FormattedMessage id="ALARM.PROFILE.VIEW" /></Button>
            <Button variant="text" onClick={() => navigate('/locations/details/' + alarm.locationId)}><FormattedMessage id="VIEW_LOCATION" /></Button>
        </ButtonGroup>
    </>
        : <Card>
            <CardHeader title={editFlag !== null ? <FormattedMessage id="ALARM.PROFILE.FORM" /> : <FormattedMessage id="ALARM.PROFILE.DETAILS_CARD" />}
                action={<RenderActions editFlag={editFlag !== null} navigate={navigate} alarm={alarm} updateAlarmsData={updateAlarmsData} />}
                subheader={<Chip size="small" color={alarm.enabled ? "success" : "default"} label={<FormattedMessage id={alarm.enabled ? "ENABLED" : "DISABLED"} />} icon={alarm.enabled ? <NotificationsActiveOutlined fontSize="small" /> : <NotificationsPausedOutlined fontSize="small" />} />}
            />
            <CardContent>
                {editFlag !== null ? <AlarmProfileForm alarm={alarm} updateAlarmsData={updateAlarmsData} /> : <AlarmProfileDetails alarm={alarm} />}
            </CardContent>
        </Card>;
}

