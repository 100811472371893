import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from 'react-redux';
import { selectResourceTypeGroupsConf } from "redux/configurationSlice";
import { MeasurementsTrendGraph, DateRangeSelector, ReportingButtons, OWAttribution } from "components";
import { Box, Card, CardContent, CardHeader, Grid, Typography, useMediaQuery } from "@mui/material";
import { ComplianceTag, LoginRequired, LocationMapTab, LocationDetailsTabs, LatestMeasurementsTabs, SamplingLogsSamplesCount, SamplingLogTypeDistributionPie/*, SamplingLogQualityDistributionPie */ } from 'components';


export default function LocationDetailsView(props) {
    //TODO: more work needed
    const { location, publicView } = props;
    // const mediumScreen = useMediaQuery(theme => theme.breakpoints.down('lg'));
    const smallScreen = useMediaQuery(theme => theme.breakpoints.down('md'));
    const resTypeGroups = useSelector(selectResourceTypeGroupsConf);
    const locale = useIntl().locale;

    const gridItems = resTypeGroups.map((group) => {
        if (!publicView) return {
            title: "RESOURCE_TYPE.GROUP." + group.name,
            ...(group.priorityResources && group.priorityResources.length && { groupSelectable: group.priorityResources }),
            resourceTypes: group.name === "SERVICE" ?   // if SERVICE type group
                location.serviceResourceTypes           // get locationServiceTypes
                : location.resourceTypes.filter(res => group.resourceTypes.includes(res)), // else get otherResTypes
        };
        else if (publicView && (group.name !== "SERVICE" && group.name !== "SENSOR")) return {
            title: "RESOURCE_TYPE.GROUP." + group.name,
            resourceTypes: location.resourceTypes.filter(res => group.resourceTypes.includes(res))
        }
        else return null;
    }).filter(el => el !== null);

    return (
        <Grid container direction="column" spacing={2}>
            <Grid item container direction="row" spacing={2}>
                {!publicView && <Grid item xs={12} sm={12} md={12} lg={8} xl={8} sx={{ display: 'flex' }}>
                    <LatestMeasurementsTabs location={location} />
                </Grid>}
                {
                    publicView ?
                        <>
                            <Grid item xs={12} sm={12} md={12} lg={6} xl={6} sx={{ display: 'flex' }}>
                                <Card sx={{ width: '100%' }}>
                                    <CardHeader title={<Typography variant="h5">{location.name}</Typography>} subheader={<ComplianceTag locationId={location._id} />} />
                                    <CardContent>{location.description[locale]}</CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={6} xl={6} sx={{ display: 'flex' }}>
                                <Card sx={{ width: '100%' }}><Box>
                                    <LocationMapTab location={location} />
                                </Box></Card>
                            </Grid>
                        </>
                        : <Grid item xs={12} sm={12} md={12} lg={4} xl={4} sx={{ display: 'flex' }}>
                            <LocationDetailsTabs location={location} />
                        </Grid>
                }
                {!!gridItems.flatMap(item => item.resourceTypes).length && <Grid item xs={12} sm={12} md={12} lg={publicView ? 12 : 8} xl={publicView ? 12 : 8} sx={{ display: 'flex' }}>
                    <Card sx={{ width: '100%' }}>
                        <CardContent >
                            <DateRangeSelector column={smallScreen} />
                        </CardContent>
                    </Card>
                </Grid>}
                {!publicView && <Grid item xs={12} sm={12} md={12} lg={4} xl={4} sx={{ display: 'flex' }} >
                    <Card sx={{ width: '100%' }}>
                        <CardContent sx={{ display: 'flex', justifyContent: 'center' }}>
                            <ReportingButtons locationId={location._id} column={smallScreen} />
                        </CardContent>
                    </Card>
                </Grid>}
            </Grid>
            <Grid item container direction="row" spacing={2}>
                {gridItems.map(item => (
                    item.resourceTypes.length ?
                        <Grid key={item.title} item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <MeasurementsTrendGraph
                                selectable={item.groupSelectable}
                                title={<FormattedMessage id={item.title} />}
                                attribution={item.title.includes("SERVICE") ? <OWAttribution /> : undefined}
                                locationId={location._id}
                                resourceTypes={item.resourceTypes}
                                type={item.title.includes("SERVICE") ? "service" : item.title.includes("SENSOR") ? "sensor" : "manual"}
                            />
                        </Grid> : null
                ))}
            </Grid>
            {!publicView ? <Grid item container direction="row" spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={8} xl={8} sx={{ display: 'flex' }}>
                    <SamplingLogsSamplesCount locations={[location._id]} />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4} xl={4} sx={{ display: 'flex' }}>
                    <SamplingLogTypeDistributionPie locations={[location._id]} />
                </Grid>
                {/* <Grid item xs={12} sm={12} md={6} lg={3} xl={3} sx={{ display: 'flex' }}>
                    <SamplingLogQualityDistributionPie locations={[location._id]} />
                </Grid> */}
            </Grid> : <Grid item>
                <LoginRequired messageId="LOGIN_MORE" />
            </Grid>}
        </Grid>
    );
}