import { useCallback } from "react";
import { useSelector } from "react-redux";
import { selectResourcesTypesConf } from "redux/configurationSlice";
import { selectAllLocations } from 'redux/locationsSlice';
import { Box, Card, CardContent, CardHeader, Divider, Grid, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { formatDateLocale } from 'utils';
import { ComplianceTag, DisplayResourceName } from 'components';
import { selectFontWeight } from "redux/appSlice";


export default function AddSampleHistoryReport(props) {
    const { reportData, page } = props;
    const locations = useSelector(selectAllLocations);
    const resourceTypesConf = useSelector(selectResourcesTypesConf);
    const fontWeight = useSelector(selectFontWeight);
    const renderSampleLog = useCallback(() => {
        const row = reportData.samplingLogsArray[page - 1];
        return <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ pt: '0 !important' }}>
                <ComplianceTag status={row.complianceIndex} />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                <Typography color="primary" variant="h6"><FormattedMessage id="SAMPLE_LOG" /> {page}</Typography>
                <Box sx={{ mt: 1 }}>
                    <Typography color="primary" variant='body2' sx={{ display: 'inline-block' }} ><FormattedMessage id='LOCATION' /></Typography>
                    <Typography variant='body1' sx={{ display: 'inline', p: 1 }}>{locations.find(loc => loc._id === row.locationId).name}</Typography>
                </Box>
                <Box sx={{ mt: 1 }}>
                    <Typography color="primary" variant='body2' sx={{ display: 'inline-block' }} ><FormattedMessage id='TIMESTAMP' /></Typography>
                    <Typography variant='body1' sx={{ display: 'inline', p: 1 }} >{formatDateLocale(row.timestamp)}</Typography>
                </Box>
                <Box sx={{ mt: 1 }}>
                    <Typography color="primary" variant='body2' sx={{ display: 'inline-block' }} ><FormattedMessage id='SAMPLE_LOG.COLLECTOR' /></Typography>
                    <Typography variant='body1' sx={{ display: 'inline', p: 1 }} >{row.collector}</Typography>
                </Box>

                {Object.keys(row.labels).map((key) => {
                    const label = row.labels[key];
                    if (label !== "") {
                        return (
                            <div key={key}>
                                <Typography color="primary" variant="body2"><FormattedMessage id={`SAMPLE_MARK_OF_${key.toUpperCase()}`} /></Typography>
                                <Typography variant="body1">{label}</Typography>
                            </div>
                        );
                    }
                    return null;
                })}
                {row.notes?.length > 0 && <Box sx={{ my: 1 }}>
                    <Typography color="primary" variant="body2" sx={{ display: 'inline-block' }}><FormattedMessage id="NOTES" /></Typography>
                    <Typography variant="body1" sx={{ display: 'inline', p: 1 }}>{row.notes}</Typography>
                </Box>}
            </Grid>
            {!!row.errors?.number && <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                <Typography color="error.main" variant="subtitle1"><FormattedMessage id="INVALID_COLUMNS" /> ({row.errors.number})</Typography>
                {row.errors.array.map((el, i) => <Box key={el.column + i} sx={{ display: "inline-flex", width: "100%" }}>
                    <Box sx={{ width: "60%" }}>
                        <Typography color="text.secondary" variant="subtitle2"><FormattedMessage id="HEADER" /></Typography>
                        <Typography variant="h6">{el.column}</Typography>
                    </Box>
                    <Box sx={{ width: "40%" }}>
                        <Typography color="text.secondary" variant="subtitle2"><FormattedMessage id="VALUE" /></Typography>
                        <Typography variant="h6">{el.value}</Typography>
                    </Box>
                </Box>)}
                <Divider />
            </Grid>}
            <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ mb: 3 }}>
                <Typography color="primary" variant='body2' sx={{ width: '100%', pl: 1, mb: 1 }}><FormattedMessage id="MEASUREMENTS" /></Typography>
                {row.data.map((el, index) => {
                    const resType = resourceTypesConf.find(res => res.key === el.resourceTypeKey);
                    return <Card key={index} sx={{ border: el.complianceIndex === 3 ? '2px solid red' : '', mr: 1, mb: 1 }} >
                        <CardHeader sx={{ pl: 1 }} title={<Typography sx={{ pr: 1, fontWeight: fontWeight + 500 }} color={el.complianceIndex === 3 ? "error" : "inherit"} variant="body1"><DisplayResourceName resource={resType} /></Typography>} action={el.complianceIndex === 3 && <ComplianceTag status={el.complianceIndex} />} />
                        <CardContent sx={{ pt: 0 }}>
                            <Typography color={el.complianceIndex === 3 ? "error" : "primary"} variant="body1" display="inline" sx={{ fontWeight: fontWeight + 500 }}>{el.value}</Typography>
                            <Typography variant="body2" display="inline">{resType.unit}</Typography>
                        </CardContent>
                    </Card>

                })}
            </Grid>
        </Grid >
    }, [reportData, page, resourceTypesConf, locations, fontWeight])

    return <Box>
        {reportData.samplingLogsArray.length ? renderSampleLog() : <Typography><FormattedMessage id="NO_VALID_LOGS" /></Typography>}
    </Box>;
}