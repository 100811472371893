import React, { useCallback, useState, useRef } from "react";
import { Box, Grid, Stack } from '@mui/material';
import {
    SamplesTableContainer,
    SampleReportingFilters,
    SamplingLogsSamplesCount,
    // SamplingLogQualityDistributionPie,
    SamplingLogTypeDistributionPie
} from "components";

export default function SamplesView(props) {
    const { location } = props;
    const chartRef = useRef(null);

    // STATE & CALLBACKS
    const [locations, setLocations] = useState(location ? [location] : []);
    const [measurementTypes, setMeasurementTypes] = useState([]);
    const [sampleTypes, setSampleTypes] = useState([]);
    const [qualityTypes, setQualityTypes] = useState([]);
    const [pictureSample, togglePictureSample] = useState(false);


    const filterHandler = useCallback((data, type) => {
        switch (type) {
            case 'locations':
                setLocations(data);
                return;
            case 'samples':
                setSampleTypes(data);
                return;
            case 'measurements':
                setMeasurementTypes(data);
                return;
            case 'quality':
                setQualityTypes(data);
                return;
            case 'picture':
                togglePictureSample(data);
                return;
            default:
                setSampleTypes([]);
                setMeasurementTypes([]);
                setQualityTypes([]);
                togglePictureSample(false);
                return;
        }
    }, [setSampleTypes, setLocations, setMeasurementTypes, setQualityTypes, togglePictureSample]);

    const renderCharts = useCallback(() => {
        return <Stack direction="column" sx={{ width: '100%' }} spacing={2}>
            <SampleReportingFilters
                multiLocation
                selectedLocations={locations}
                filterHandler={filterHandler}
                sampleTypes={sampleTypes}
                qualityTypesSelected={qualityTypes}
                pictureSample={pictureSample}
            />
            <Box sx={{ ml: -2 }}>
                <Grid container spacing={2} alignItems="stretch">
                    <Grid item xl={8} lg={8} md={12} sm={12} xs={12} sx={{ display: 'flex' }}>
                        <SamplingLogsSamplesCount
                            locations={locations.map(loc => loc._id)}
                            resourceTypes={measurementTypes.map(el => el.value)}
                            sampleTypes={sampleTypes.map(sample => sample.key)}
                            qualityTypes={qualityTypes.map(quality => quality.key)}
                            pictureSample={pictureSample}
                            chartRef={chartRef}
                        />

                    </Grid>
                    <Grid item xl={4} lg={4} md={6} sm={6} xs={12} sx={{ display: 'flex' }}>
                        <SamplingLogTypeDistributionPie
                            locations={locations.map(loc => loc._id)}
                            resourceTypes={measurementTypes.map(el => el.value)}
                            sampleTypes={sampleTypes.map(sample => sample.key)}
                            qualityTypes={qualityTypes.map(quality => quality.key)}
                            pictureSample={pictureSample}
                        />
                    </Grid>
                    {/* <Grid item xl={3} lg={3} md={6} sm={6} xs={12} sx={{ display: 'flex' }}>
                        <SamplingLogQualityDistributionPie
                            locations={locations.map(loc => loc._id)}
                            resourceTypes={measurementTypes.map(el => el.value)}
                            sampleTypes={sampleTypes.map(sample => sample.key)}
                            qualityTypes={qualityTypes.map(quality => quality.key)}
                            pictureSample={pictureSample}
                        />
                    </Grid> */}
                </Grid>
            </Box>
            <SamplesTableContainer
                locations={locations.map(loc => loc._id)}
                sampleTypes={sampleTypes.map(sample => sample.key)}
                qualityTypes={qualityTypes.map(quality => quality.key)}
                pictureSample={pictureSample}
                chartRef={chartRef}
            />
        </Stack>

    }, [locations, measurementTypes, sampleTypes, qualityTypes, pictureSample, chartRef, filterHandler])

    return <>
        {renderCharts()}
    </>
}