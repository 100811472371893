import React, { useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, Typography, useMediaQuery, useTheme } from "@mui/material";
import LocationDetailsForm from "./LocationDetailsForm";
import { useSelector, useDispatch } from "react-redux";
import { selectLocationDetails, updateLocationState, updateLocationGroupState } from "redux/locationsSlice";
import { selectMapProps } from "redux/mapSlice";
import { userSelector } from "redux/userSlice";
import { FormattedMessage } from "react-intl";
import API from "api";



export default function LocationDetailsDialogForm(props) {
    const { openFormDialog, handleCloseForm, successMsg, setSuccessMsg, locationId, locationGroupId } = props;
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const dispatch = useDispatch();
    const { token } = useSelector(userSelector);
    const mapProps = useSelector(selectMapProps);
    const location = useSelector(state => selectLocationDetails(state, locationId));
    const locationGroup = locationGroupId ? false : true;
    const [errorMsg, setErrorMsg] = useState("");
    const [updatedLocation, setUpdatedLocation] = useState({});

    const hasAnythingChanged = (obj1, obj2) => {
        for (const key in obj1) {
            if (JSON.stringify(obj1[key]) !== JSON.stringify(obj2[key])) {
                return true
            }
        }
        return false
    }

    const handleSubmit = () => {
        const locationGroupID = locationGroupId ? locationGroupId : null;
        const image = updatedLocation.picture ? updatedLocation.picture : null

        const emptyLocation = {
            name: "",
            position: [mapProps.defaultY, mapProps.defaultX],
            description: { en: "", hr: "" },
            ...(!locationGroup) && { measurePointType: 0 },
            ...(!locationGroup) && { resourceTypes: [] },
            ...(!locationGroup) && { serviceResourceTypes: [] },
            ...(!locationGroup) && { locationGroupId: locationGroupID }
        }

        if ((!locationId && !hasAnythingChanged(updatedLocation, emptyLocation)) || (location && !hasAnythingChanged(updatedLocation, location))) {
            setErrorMsg(<FormattedMessage id="NO_UPDATE_RESPONSE" />);
            setSuccessMsg("");
        }
        else if (updatedLocation.name === "" || updatedLocation.position === []) {
            setErrorMsg(<FormattedMessage id="MISSING_REQUIRED_FIELDS" />);
            setSuccessMsg("");
        }
        else {
            // location changed
            const newLocation = {
                name: updatedLocation.name,
                longitude: updatedLocation.position[0],
                latitude: updatedLocation.position[1],
                description: updatedLocation.description,
                ...(!locationGroup) && { measurePointType: updatedLocation.measurePointType },
                ...(!locationGroup) && { resourceTypes: updatedLocation.resourceTypes.join(',') },
                ...(!locationGroup) && { serviceResourceTypes: updatedLocation.serviceResourceTypes.join(',') },
                ...(!locationGroup) && { locationGroupId: locationGroupID }
            }

            if (locationId) {
                const updateFunction = locationGroup ? API.locations.updateLocationGroup : API.locations.updateLocation;
                // call API to update location/locationGroup
                updateFunction(token, locationId, newLocation, image).then(({ data }) => {
                    // set success message and dispatch update
                    setSuccessMsg(<FormattedMessage id="SUBMIT_LOCATION_RESPONSE" />);
                    locationGroup ? dispatch(updateLocationGroupState(data)) : dispatch(updateLocationState(data))
                    // after timeout close dialog
                    setTimeout(() => {
                        handleCloseForm();
                    }, 1500);
                }).catch(e => {
                    if (e.response.status === 403) setErrorMsg(<FormattedMessage id="ACCESS_DENIED" />);
                    else setErrorMsg(e.response.data || e.message);
                });
            }
            else {
                const createFunction = locationGroup ? API.locations.createLocationGroup : API.locations.createLocation;
                // call API to create location/locationGroup
                createFunction(token, newLocation, image).then(({ data }) => {
                    // set success message and dispatch update
                    setSuccessMsg(<FormattedMessage id="SUBMIT_LOCATION_RESPONSE" />);
                    locationGroup ? dispatch(updateLocationGroupState(data)) : dispatch(updateLocationState(data))
                    // after timeout close dialog
                    setTimeout(() => {
                        handleCloseForm();
                    }, 1500);
                }).catch(e => {
                    if (e.response && e.response.status === 403) setErrorMsg(<FormattedMessage id="ACCESS_DENIED" />);
                    else setErrorMsg(e.response.data || e.message);
                });
            }
        }
    }

    return (
        <Dialog
            open={openFormDialog}
            fullScreen={fullScreen}
            fullWidth
            maxWidth='md'
            aria-labelledby="location-details-form-title"
            scroll="paper"
        >
            <DialogTitle id="location-details-form-title">
                <FormattedMessage id={locationGroup ?
                    locationId ? "UPDATE" : "ADD_LOCATION_GROUP" :
                    locationId ? "UPDATE" : "ADD_LOCATION"}
                />
                {location && location.name}
                {!successMsg ? <Typography variant='caption' sx={{ display: "block", color: theme.palette.warning.main }}><FormattedMessage id="LOCATION_SUBTITLE" /></Typography> : null}
                <Divider />
            </DialogTitle>

            {successMsg ?
                <DialogContent>
                    <DialogContentText sx={{ textAlign: 'center', color: theme.palette.text.primary }}>{successMsg}</DialogContentText>
                </DialogContent>
                :
                <>
                    <DialogContent sx={fullScreen ? null : { px: 8 }}>
                        {errorMsg ? <DialogContentText sx={{ textAlign: 'center', color: theme.palette.error.main }}>{errorMsg}</DialogContentText> : null}

                        <LocationDetailsForm
                            locationId={locationId}
                            location={location}
                            locationGroup={locationGroup}
                            setUpdatedLocation={setUpdatedLocation}
                            setErrorMsg={setErrorMsg}
                        />

                    </DialogContent>
                    <Divider variant="middle" sx={{ mt: 1, mx: 3 }} />
                    <DialogActions sx={{ mx: 2 }}>
                        <Button onClick={handleCloseForm}><FormattedMessage id="CANCEL" /></Button>
                        <Button disabled={!!errorMsg} onClick={handleSubmit}><FormattedMessage id="SUBMIT" /></Button>
                    </DialogActions>
                </>
            }
        </Dialog>
    );
}