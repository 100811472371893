import locationPinDark from './icons/dark_pin.png';
import locationPinLight from './icons/light_pin.png';
import locationAddPinDark from './icons/dark_pin_add.png';
import locationAddPinLight from './icons/light_pin_add.png';
import poiLocationDark from './icons/villa_dark.png';
import poiLocationLight from './icons/villa_light.png';
import poiAssetDark from './icons/asset_dark.png';
import poiAssetLight from './icons/asset_light.png';
import poi3ppAssetDark from './icons/3ppAsset_dark.png';
import poi3ppAssetLight from './icons/3ppAsset_light.png';
import poiLight from './icons/poi_light.png';
import poiDark from './icons/poi_dark.png';
import dot from './icons/dot.png';
import { Stroke, Style, Icon } from 'ol/style';

const connection = (themeMode) => {
    return new Style({
        stroke: new Stroke({
            color: themeMode === 'dark' ? 'white' : 'black',
            width: 1,
            lineDash: [2]
        }),
    })
}

const measurePoint = (themeMode) => {
    return new Style({
        image: new Icon({
            anchor: [0.5, 46],
            anchorXUnits: "fraction",
            anchorYUnits: "pixels",
            src: themeMode === 'dark' ? locationPinLight : locationPinDark,
            scale: 0.4
        })
    })
}

const location = () => {
    return new Style({
        image: new Icon({
            anchor: [0.5, 10],
            anchorXUnits: "fraction",
            anchorYUnits: "pixels",
            src: dot,
            scale: 0.5
        })
    })
}

const locationNew = (themeMode) => {
    return new Style({
        image: new Icon({
            anchor: [0.5, 46],
            anchorXUnits: "fraction",
            anchorYUnits: "pixels",
            src: themeMode === 'dark' ? locationAddPinLight : locationAddPinDark,
            scale: 0.5
        })
    })
}

const pointOfInterest = (themeMode, type) => {
    const getType = () => {
        switch (Number(type)) {
            case 1:
                return themeMode === 'dark' ? poiLocationLight : poiLocationDark;
            case 2:
                return themeMode === 'dark' ? poiAssetLight : poiAssetDark;
            case 3:
                return themeMode === 'dark' ? poi3ppAssetLight : poi3ppAssetDark;
            case 0:
            default:
                return themeMode === 'dark' ? poiLight : poiDark;

        }
    }

    return new Style({
        image: new Icon({
            anchor: [0.5, 46],
            anchorXUnits: "fraction",
            anchorYUnits: "pixels",
            src: getType(),
            scale: 0.5
        })
    })
}

const MARKERS = {
    measurePoint,
    connection,
    location,
    locationNew,
    pointOfInterest,
};

export default MARKERS;