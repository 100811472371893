import React from 'react';
import { useNavigate } from 'react-router';
import { FormattedMessage } from 'react-intl';
import { permissionSelector } from 'redux/userSlice';
import { useSelector } from 'react-redux';
import { selectStagingSamplesFeature } from 'redux/configurationSlice';
import { ListItemButton, ListItemIcon, ListItemText, Divider, List, Tooltip } from '@mui/material';
import { AddchartOutlined, BarChartOutlined, ContentPasteOutlined, HomeOutlined, LocationOnOutlined, MapOutlined, NotificationImportantOutlined, ShowChartOutlined, NoteAddOutlined, GavelOutlined } from '@mui/icons-material';


const navArray = [
  {
    item: 'HomePage',
    onClick: (navigateCallback) => navigateCallback("/home"),
    iconElement: <HomeOutlined />,
    textElement: <FormattedMessage id="HOME" />
  },
  {
    item: 'LocationsPage',
    onClick: (navigateCallback) => navigateCallback("/locations"),
    iconElement: <LocationOnOutlined />,
    textElement: <FormattedMessage id="LOCATIONS" />
  },
  {
    item: 'MapPage',
    onClick: (navigateCallback) => navigateCallback("/map"),
    iconElement: <MapOutlined />,
    textElement: <FormattedMessage id="MAP" />
  },

];

const reportArray = [
  {
    item: 'MeasurementsPage',
    onClick: (navigateCallback) => navigateCallback("/measurements"),
    iconElement: <ShowChartOutlined />,
    textElement: <FormattedMessage id="MEASUREMENTS" />
  },
  {
    item: 'SamplesPage',
    onClick: (navigateCallback) => navigateCallback("/samples"),
    iconElement: <BarChartOutlined />,
    textElement: <FormattedMessage id="SAMPLES" />
  }
];

const stagingSamples = {
  item: 'StagingSamplesPage',
  onClick: (navigateCallback) => navigateCallback("/staging-samples"),
  iconElement: <GavelOutlined />,
  textElement: <FormattedMessage id="STAGING_SAMPLES" />
}

const formsArray = [
  {
    item: 'AddSamplePage',
    onClick: (navigateCallback) => navigateCallback("/sampleEntry"),
    iconElement: <AddchartOutlined />,
    textElement: <FormattedMessage id="ADD_SAMPLE" />
  },
  {
    item: 'AddSampleHistoryPage',
    onClick: (navigateCallback) => navigateCallback("/sampleEntry/history"),
    iconElement: <NoteAddOutlined />,
    textElement: <FormattedMessage id="ADD_SAMPLE_FILE" />
  },
]

const notifArray = [
  {
    item: 'AlarmsPage',
    onClick: (navigateCallback) => navigateCallback("/alarms"),
    iconElement: <NotificationImportantOutlined />,
    textElement: <FormattedMessage id="ALARMS" />
  },
  {
    item: 'Notes',
    onClick: (navigateCallback) => navigateCallback("/notes"),
    iconElement: <ContentPasteOutlined />,
    textElement: <FormattedMessage id="NOTES" />
  },
]


export default function MenuItems(props) {
  const { secondaryItems, selectedItem, open } = props;
  const createSampleRights = useSelector((state) => permissionSelector(state, 'create-sample'));
  const viewStagingSampleRights = useSelector((state) => permissionSelector(state, 'view-staging-sample'));
  const stagingFeature = useSelector((state) => selectStagingSamplesFeature(state));
  const navigate = useNavigate();

  return <List component="nav" style={{ height: 1 }}>
    {navArray.map((item, i) => (
      <ListItemButton key={item.item} selected={item.item === selectedItem} onClick={() => item.onClick(navigate)}>
        <ListItemIcon>
          <Tooltip title={open ? "" : item.textElement} placement="right" arrow>
            {item.iconElement}
          </Tooltip>
        </ListItemIcon>
        <ListItemText primary={item.textElement} />
      </ListItemButton>
    ))}
    {secondaryItems ?
      <>
        <Divider sx={{ my: 1 }} />
        {reportArray.map((item, i) => (
          <ListItemButton key={item.item} selected={item.item === selectedItem} onClick={() => item.onClick(navigate)}>
            <ListItemIcon>
              <Tooltip title={open ? "" : item.textElement} placement="right" arrow>
                {item.iconElement}
              </Tooltip>
            </ListItemIcon>
            <ListItemText primary={item.textElement} />
          </ListItemButton>
        ))}
        {stagingFeature && viewStagingSampleRights && <ListItemButton key={stagingSamples.item} selected={stagingSamples.item === selectedItem} onClick={() => stagingSamples.onClick(navigate)}>
          <ListItemIcon>
            <Tooltip title={open ? "" : stagingSamples.textElement} placement="right" arrow>
              {stagingSamples.iconElement}
            </Tooltip>
          </ListItemIcon>
          <ListItemText primary={stagingSamples.textElement} />
        </ListItemButton>}
        {(!!createSampleRights || stagingFeature) &&
          <>
            <Divider sx={{ my: 1 }} />
            {formsArray.map((item, i) => (
              <ListItemButton key={item.item} selected={item.item === selectedItem} onClick={() => item.onClick(navigate)}>
                <ListItemIcon>
                  <Tooltip title={open ? "" : item.textElement} placement="right" arrow>
                    {item.iconElement}
                  </Tooltip>
                </ListItemIcon>
                <ListItemText primary={item.textElement} />
              </ListItemButton>
            ))}
          </>
        }
        <Divider sx={{ my: 1 }} />
        {notifArray.map((item, i) => (
          <ListItemButton key={item.item} selected={item.item === selectedItem} onClick={() => item.onClick(navigate)}>
            <ListItemIcon>
              <Tooltip title={open ? "" : item.textElement} placement="right" arrow>
                {item.iconElement}
              </Tooltip>
            </ListItemIcon>
            <ListItemText primary={item.textElement} />
          </ListItemButton>
        ))}
      </>
      : null}
  </List>;
};