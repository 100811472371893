import React from "react";
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { DisplayResourceName } from "components";
import { selectLocationDetails } from "redux/locationsSlice";
import { selectResourcesTypesConf, selectNotificationMethodConf } from "redux/configurationSlice";
import { Divider, Card, CardHeader, Box, IconButton, Stack, Tooltip, Typography, CardContent, useTheme } from '@mui/material';
import { LabelImportantOutlined, OpenInNewOutlined, ReportOutlined } from '@mui/icons-material';


function AlarmProfileDetails(props) {
    const { alarm, mini } = props;
    const location = useSelector((state) => selectLocationDetails(state, alarm.locationId));
    const resourceTypesConfig = useSelector(selectResourcesTypesConf);
    const notificationTypesConfig = useSelector(selectNotificationMethodConf);
    const defaultFontSize = useTheme().typography.fontSize;
    const intl = useIntl();
    const navigate = useNavigate();
    const findResourceType = (key, resourceTypesConfig) => {
        const foundResource = resourceTypesConfig.find(el => el.key === key)
        return foundResource
    }
    const CustomText = (props) => {
        return <Stack direction={props.direction} sx={{ mb: props.wide ? 2 : 0 }}>
            <Typography sx={{ mr: 2.5 }} variant='body2' color='primary.main'><FormattedMessage id={props.messageId} />{props.hasIcon && <Tooltip title={<FormattedMessage id='VIEW_LOCATION' />} placement="right" arrow>
                <IconButton color="primary" onClick={() => navigate('/locations/details/' + alarm.locationId)} ><OpenInNewOutlined fontSize="small" /></IconButton>
            </Tooltip>}</Typography>
            {props.children ? props.children : <Typography variant='body1'>{props.text}</Typography>}
        </Stack>
    };

    return <Stack spacing={1} direction={'column'} sx={mini ? { px: 1 } : null}>

        {/* <CustomText direction="row" messageId="ALARM.PROFILE.ACTIVE" text={""} /> */}
        <CustomText direction="column" messageId="ALARM.PROFILE.NAME" text={alarm.name} />
        <CustomText direction="column" messageId="LOCATION" text={location.name} hasIcon />

        {alarm.triggeredAlarms ? <CustomText direction="row" messageId="ALARM.PROFILE.TRIGGERED" text={<ReportOutlined sx={{ fontSize: defaultFontSize * 2.4 }} color="error" />} /> : null}

        {alarm.rules && Object.keys(alarm.rules).length ? <Box>
            <Typography variant='body2' color='primary.main'><FormattedMessage id="RULES" /></Typography>
            {Object.keys(alarm.rules).map((rule, id) => (
                <Card key={id} sx={{ width: '100%', mb: 2 }}>
                    <CardHeader title={
                        <span>
                            <LabelImportantOutlined
                                name={'severity-' + rule}
                                className={'severity-' + rule}
                                sx={(theme) => ({ width: theme.typography.fontSize * 2, height: theme.typography.fontSize * 2, pt: 1.3 })}
                            />
                            <FormattedMessage id={'SEVERITY.' + rule} />
                        </span>
                    } />
                    <CardContent sx={{ pt: 0 }}>

                        {alarm.rules[rule].notificationMethod.length ? <CustomText wide direction="column" messageId="NOTIFICATION_TYPE" text={alarm.rules[rule].notificationMethod.map(notificationType => {
                            const value = notificationTypesConfig.find(type => type.key === notificationType).value;
                            return intl.formatMessage({ id: "NOTIFICATION_OPTION." + value });
                        }).join(",")} /> : null}
                        <CustomText wide direction="column" messageId="MESSAGE" text={alarm.rules[rule].details}>
                            {!alarm.rules[rule].details && <Typography color="text.disabled" variant="subtitle1"><FormattedMessage id="NO_MESSAGE" /></Typography>}
                        </CustomText>
                        {alarm.rules[rule].filters.length
                            ? <Typography variant="body2" color='primary.main'><FormattedMessage id="FILTERS" /></Typography>
                            : <Divider>
                                <Typography variant="button" color='warning.main'><FormattedMessage id="NO_FILTERS" /></Typography>
                            </Divider>}
                        {Object.keys(alarm.rules[rule].filters).map((filter, index) => {
                            return <Box key={index} sx={{ border: '1px solid lightgray', p: 2, my: 1 }}>
                                {alarm.rules[rule].filters[filter].conditions.length
                                    ? alarm.rules[rule].filters[filter].conditions.map((condition, i, conds) => {
                                        const resType = findResourceType(condition.resourceTypeKey, resourceTypesConfig);
                                        return <Box key={i}>
                                            <Typography variant='h6' >
                                                {resType && <DisplayResourceName resource={resType} />}
                                                <Typography display="inline" variant='body1'> <FormattedMessage id={"ALARM.PROFILE." + condition.operator} /> </Typography>
                                                {condition.value}
                                                {resType && <Typography display="inline" variant='body1'>{resType.unit}</Typography>}
                                            </Typography>
                                            {i < conds.length - 1 ? <Typography variant='button' color="info.main" sx={{ width: '12%', textAlign: 'left' }} key={i}><FormattedMessage id={"FILTERS." + alarm.rules[rule].filters[filter].type} /></Typography> : null}
                                        </Box>;
                                    })
                                    : <Divider key="no-conditions-divider">
                                        <Typography variant="button" color='warning.main'><FormattedMessage id="NO_CONDITIONS" /></Typography>
                                    </Divider>}
                            </Box>
                        })}
                    </CardContent>
                </Card>

            ))}
        </Box> : <Divider>
            <Typography variant="button" color="warning.main"><FormattedMessage id="NO_RULES" /></Typography>
        </Divider>}
    </Stack >

}

export default AlarmProfileDetails;