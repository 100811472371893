import { useSelector } from "react-redux";
import { useSearchParams } from 'react-router-dom';
import { FormattedMessage } from "react-intl";
import { selectStagingSamplesFeature } from "redux/configurationSlice";
import { selectLocationDetails } from 'redux/locationsSlice'
import { permissionSelector, userSelector } from "redux/userSlice";
import { DefaultLayout, PublicLayout, EmptyState } from 'layouts';
import { StagingSamplesView, LoginRequired } from "components";
import { SplashPage } from "pages";

function StagingSamplesPage(props) {
    const [searchparams] = useSearchParams();
    const locationId = searchparams.get("locationId");
    const location = useSelector((state) => selectLocationDetails(state, locationId));

    if (!locationId) return <EmptyState component={<StagingSamplesView />} />;
    else if (location) return <EmptyState component={<StagingSamplesView location={location} />} />;
    else return <EmptyState message={<FormattedMessage id="SPLASH.UNAVAILABLE" />} />;
}

export default function SamplesPageComponent(props) {
    const { isSuccess: loggedIn } = useSelector(userSelector);
    const stagingFeature = useSelector(selectStagingSamplesFeature);
    const viewStagingSample = useSelector((state) => permissionSelector(state, 'view-staging-sample'));

    if (loggedIn && stagingFeature && viewStagingSample) return <DefaultLayout page={<StagingSamplesPage />} />;
    else if (!loggedIn) return <PublicLayout page={<LoginRequired />} />;
    else return <SplashPage />;
};


