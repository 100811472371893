import React, { useState, useRef, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { PictureGallery } from 'components';
import { Badge, Button, FormLabel } from '@mui/material';
import { FileUploadOutlined, DeleteOutlineOutlined } from '@mui/icons-material';
import { Box } from '@mui/system';

export default function PictureUploadButton(props) {
    const { setFiles, setImages, single, defaultImage } = props;
    const defaultPicture = defaultImage ? [defaultImage] : [];
    const [pictures, setPictures] = useState(defaultPicture);
    const [deleteFlag, setDeleteFlag] = useState(true);
    const [error, setError] = useState(null);
    const fileInputRef = useRef(null);

    useEffect(() => {
        if (defaultImage && pictures[0] && pictures[0].url === defaultImage.url) setDeleteFlag(false);
        else if (defaultImage && pictures[0] && pictures[0].url !== defaultImage.url) setDeleteFlag(true);
        else if (!defaultImage && !pictures.length) setDeleteFlag(false);
        else if (!defaultImage && pictures.length) setDeleteFlag(true);
        else setDeleteFlag(false);
    }, [defaultImage, pictures, setDeleteFlag])

    const handleInputChange = event => {
        let files = event.target.files;
        if (!files.length) return;

        const imgs = [];
        for (const picture of files) {
            if (!picture.type.includes('image')) setError(<FormattedMessage id="FORM.PHOTO_UPLOAD_ERROR" />);
            else imgs.push({ name: picture.name, url: URL.createObjectURL(picture) })
        }
        setPictures(imgs);

        if (setFiles) setFiles(files);
        if (setImages) setImages(imgs);
    }

    const handleInputClick = event => {
        event.target.value = null;
        setError(null);
    }

    const handleDelete = () => {
        if (setFiles) setFiles(null);
        if (setImages) setImages([]);
        setPictures(defaultPicture);
    }

    return <Box>
        <Box sx={{ pb: 2 }}>
            <FormLabel>
                <Badge
                    color="primary"
                    badgeContent={pictures.length}
                    variant={single ? "dot" : "standard"}
                    invisible={!deleteFlag}
                >
                    <FormattedMessage id={`PICTURE${!single ? "S" : ""}`} />
                </Badge>
            </FormLabel>
            <Button
                sx={{ mx: 1, alignItems: 'center' }}
                startIcon={<FileUploadOutlined />}
                variant="text"
                onClick={() => fileInputRef.current.click()}
            >
                <FormattedMessage id={!!pictures.length ? `FILE${!single ? "S" : ""}_CHANGE` : `FILE${!single ? "S" : ""}_SELECT`} />
            </Button>
            {deleteFlag && <Button color="warning" onClick={handleDelete} startIcon={<DeleteOutlineOutlined />}><FormattedMessage id={`FILE${!single ? "S" : ""}_DELETE`} /></Button>}
        </Box>
        {error !== null && error}
        <input
            name="pictures"
            type="file"
            multiple={!single}
            accept="image/png, image/jpeg, image/jpg"
            ref={fileInputRef}
            onChange={handleInputChange}
            onClick={handleInputClick}
            style={{ display: "none" }}
        />
        <PictureGallery pictures={pictures} />
    </Box>;
}